
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUserSubject = new BehaviorSubject<any>({});
  public currentUser = this.currentUserSubject.asObservable();
  
  constructor() {
   const user = localStorage.getItem('apartment_user');
   try{
    if(user) {
      this.currentUserSubject.next(jwt_decode(user))
    }
   }catch(e){
    localStorage.removeItem('apartment_user');
  //  localStorage.removeItem('apartmentPayload');
   }
  
  }

  isLoggedIn() {
    return localStorage.getItem('apartment_user') ? true : false;
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value
  }
}
