<div class="apt-search-overlay" *ngIf="enableOverlay" (click)="isCollapsed = !isCollapsed;enableOverlay = false"> </div>
<div class="apt-searchlist-overlay" *ngIf="enableTextSearchOverlay"
    (click)="enableTextSearchOverlay = false; searchResults = []"> </div>
    <!-- <div class="apt-searchfilter-overlay d-none"> </div> -->
<div class="search-box apt-search-open position-relative"
    [ngClass]="enableTextSearchOverlay == true || isCollapsed == false ? 'apt-sbg':''">
    <form [formGroup]="searchForm" (ngSubmit)="doSearch()" class="apt-form">
        <div class="apt-msearch">
            <div class="apt-gsearch ">
                <div class="input-group input-group-lg">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ti ti-building-skyscraper"></i></span>
                    </div>
                    <input type="text" class="form-control px-0 border-left-0 border-right-0"
                        (input)="onSearchTermChange($event);" placeholder="Search complex name " #searchText
                        formControlName="complex_name">
                    <!-- <div class="aptgsearch-list">
                        <ul class="position-absolute" *ngIf="searchResults && searchResults.length > 0">
                            <li *ngFor="let result of searchResults" (click)="selectComplexName(result)">{{
                                result.complex_name | titlecase }}</li>
                        </ul>
                    </div> -->

                    <div class="input-group-append">
                        
                        <button class="btn btn-secondary" type="button"
                            (click)="isCollapsed = !isCollapsed;isCollapsed == true ? enableOverlay = false:enableOverlay = true; enableTextSearchOverlay = false;searchResults=[]"
                            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"><i
                                class="ti ti-adjustments-horizontal"></i></button>
                        <button class="btn btn-primary" type="button" (click)="doSearch()"><i
                                class="fe fe-search"></i></button>
                    </div>
                    <div class="apt-seloader" *ngIf="textLoader">
                        <span class="loader"></span>
                    </div>
                    
                </div>
                <div class="apt-searchr" *ngIf="searchResults && searchResults.length > 0">
                    <div class="apt-searchr-inner">
                        <ul  >
                            <li *ngFor="let result of searchResults" (click)="selectComplexName(result)">{{
                                (result.manual_complex_name || result.complex_name) | titlecase }}</li>
                        </ul>
                    </div>
              
                </div>
                <!-- <div class="apt-filterresult ">
                  
                    <div class="apt-filterresultlist ">
                        <button class="apt-filterclose"><i class="ti ti-circle-x"></i></button>
                        <ul>
                            <li class="apt-lism">
                                <div class="f-title"><i class="ti ti-map-2 mr-1"></i>State <span>(1)</span></div>
                                <div class="f-valm"><span class="f-val" title="Massachusetts">Massachusetts</span></div>
                            </li>
                            <li class="">
                                <div class="f-title"><i class="ti ti-map-pin mr-1"></i>City <span>(3)</span></div><span>
                                    <div class="f-valm"><span class="f-val" title="WESTMONT (Illinois)"> WESTMONT (Illinois)</span></div>
                                    <div class="f-valm"><span class="f-val" title="NEVADA (WILLISVILLE)"> NEVADA (WILLISVILLE)</span></div>
                                    <div class="f-valm"><span class="f-val" title="NEWTON (PONCA)"> NEWTON (PONCA)</span></div>
                                </span>
                            </li>
                            <li class="f-wauto apt-lism">
                                <div class="f-title"><i class="ti ti-current-location mr-1"></i>Zip <span>(4)</span></div><span>
                                    <div class="f-valm"><span class="f-val" title="60559"> 60559</span></div>
                                    <div class="f-valm"><span class="f-val" title="60121"> 60121</span></div>
                                    <div class="f-valm"><span class="f-val" title="60221"> 60221</span></div>
                                    <div class="f-valm"><span class="f-val" title="60152"> 60152</span></div>
                                </span>
                            </li>
                            <li class="">
                                <div class="f-title"><i class="ti ti-building-broadcast-tower mr-1"></i>MSO <span>(5)</span></div><span>
                                    <div class="f-valm"><span class="f-val" title="BRIGHTHOUSE, CHARTER, COMCAST, COX, MEDIACOM">
                                            BRIGHTHOUSE</span></div>
                                            <div class="f-valm"><span class="f-val" title="BRIGHTHOUSE, CHARTER, COMCAST, COX, MEDIACOM">
                                                CHARTER</span></div>
                                                <div class="f-valm"><span class="f-val" title="BRIGHTHOUSE, CHARTER, COMCAST, COX, MEDIACOM">
                                                    COMCAST</span></div>
                                                    <div class="f-valm"><span class="f-val" title="BRIGHTHOUSE, CHARTER, COMCAST, COX, MEDIACOM">
                                                        COX</span></div>
                                                        <div class="f-valm"><span class="f-val" title="BRIGHTHOUSE, CHARTER, COMCAST, COX, MEDIACOM">
                                                            MEDIACOM</span></div>
                                </span>
                            </li>
                        
                        </ul>
                    </div>
                </div> -->
            </div>
        </div>
    </form>


    <!-- advanced search -->

    <div id="collapseBasic" [collapse]="isCollapsed">
        <section class="apt-search">
            <div class=" text-center">
                <div class="row">
                    <div class="col-lg-12">
                        <form [formGroup]="searchForm" (ngSubmit)="doSearch()" class="apt-form">
                            <div class="row ">

                                <div class="col-lg-12 apt-hide ">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-map-2"></i>
                                                </span>
                                            </div>
                                            <ng-select class="px-0 border-left-0 border-right-0" placeholder="State"
                                                formControlName="state" [multiple]="false" [closeOnSelect]="true"
                                                [dropdownPosition]="'bottom'" [loading]="isloading['state']"
                                                [items]="stateList" bindValue="state" bindLabel="state_full_name"
                                                [virtualScroll]="true" (clear)="onClear('state')"
                                                (remove)="onRemove('state',$event)" (change)="getLoaded('county')"
                                                notFoundText="No records found">
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let states of items | slice:0:2">
                                                        <span class="ng-value-label">
                                                            {{states.state_full_name}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(states)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 2">
                                                        <span class="ng-value-label more">{{items.length - 2}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                            <div
                                                *ngIf="searchForm.controls['state'].invalid && (searchForm.controls['state'].dirty || searchForm.controls['state'].touched) && searchForm.controls['state'].errors">
                                                <span class="text-danger error-has"
                                                    *ngIf="searchForm.controls['state'].errors.required">
                                                    State is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 apt-hide ">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-map"></i>
                                                </span>
                                            </div>

                                            <ng-select class="px-0 border-left-0 border-right-0" 
                                                placeholder="County"
                                                formControlName="county" 
                                                [multiple]="true" 
                                                [closeOnSelect]="false" 
                                                [dropdownPosition]="'bottom'"
                                                [loading]="isloading['county']" 
                                                [items]="countyList" 
                                                bindLabel="county_label" 
                                                [virtualScroll]="true" 
                                                (clear)="onClear('county')" 
                                                (remove)="onRemove('county',$event)" 
                                                (change)="getLoaded('city')"
                                                (focusout)="checkDependent('county')"
                                                notFoundText="No records found"> 

                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let counties of items | slice:0:2">
                                                        <span class="ng-value-label">
                                                            {{counties.county_label}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(counties)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 2">
                                                        <span class="ng-value-label more">{{items.length - 2}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 apt-hide ">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-map-pin"></i>
                                                </span>
                                            </div>

                                            <ng-select class="px-0 border-left-0 border-right-0" 
                                                placeholder="City"
                                                formControlName="city" 
                                                [multiple]="true" 
                                                [closeOnSelect]="false" 
                                                [dropdownPosition]="'bottom'"
                                                [loading]="isloading['city']" 
                                                [items]="cityList" 
                                                bindLabel="city"
                                                [virtualScroll]="true"  
                                                (clear)="onClear('city')" 
                                                (remove)="onRemove('city',$event)"  
                                                (focusout)="checkDependent('city')"                                             
                                                (change)="getLoaded('zip')"
                                                notFoundText="No records found">

                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear"
                                                    *ngIf="cityList?.length > 0">
                                                    <div class="ng-value" *ngFor="let cities of items | slice:0:2">
                                                        <span class="ng-value-label">
                                                            {{cities.city}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(cities)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 2">
                                                        <span class="ng-value-label more">{{items.length - 2}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                            <div
                                                *ngIf="searchForm.controls['city'].invalid && (searchForm.controls['city'].dirty || searchForm.controls['city'].touched) && searchForm.controls['city'].errors">
                                                <span class="text-danger error-has"
                                                    *ngIf="searchForm.controls['city'].errors.required">
                                                    City is required
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 apt-hide  apt-zip">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-current-location"></i>
                                                </span>
                                            </div>

                                            <ng-select class="px-0 border-left-0 border-right-0" 
                                                placeholder="Zip" 
                                                [dropdownPosition]="'bottom'"
                                                formControlName="zip" 
                                                [multiple]="true" 
                                                [closeOnSelect]="false"
                                                [loading]="isloading['zip']" 
                                                [items]="zipList" 
                                                bindLabel="zip" 
                                                [virtualScroll]="true" 
                                                (clear)="onClear('zip')"  
                                                notFoundText="No records found" >                                                

                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let zips of items | slice:0:3">
                                                        <span class="ng-value-label">
                                                            {{zips.zip}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(zips)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 3">
                                                        <span class="ng-value-label more">{{items.length - 3}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 apt-hide  apt-zip">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-building-broadcast-tower"></i>
                                                </span>
                                            </div>
                                            <ng-select class="px-0 border-left-0 border-right-0" placeholder="MSO"
                                                formControlName="mso" [multiple]="true" [closeOnSelect]="false"
                                                [dropdownPosition]="'bottom'" [loading]="isloading['mso']"
                                                [items]="msoList" bindLabel="name" bindValue="name"
                                                [virtualScroll]="true" notFoundText="No records found">
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let mso of items | slice:0:2">
                                                        <span class="ng-value-label">
                                                            {{mso.name}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(mso)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 2">
                                                        <span class="ng-value-label more">{{items.length - 2}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-lg-12 apt-hide  apt-zip">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-tags"></i>
                                                </span>
                                            </div>
                                            <ng-select placeholder="Tags" formControlName="tags" append="body" [dropdownPosition]="'bottom'"
                                             bindLabel="tag_name" [multiple]="true" [closeOnSelect]="false" [items]="tags"
                                            [searchable]="true" [clearable]="true">
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value" *ngFor="let tag of items | slice:0:1">
                                                        <span class="ng-value-label">
                                                            {{tag.tag_name}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(tag)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 1">
                                                        <span class="ng-value-label more">{{items.length - 1}}
                                                            more...</span>
                                                    </div>

                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 apt-hide  apt-zip">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-building-skyscraper"></i>
                                                </span>
                                            </div>
                                            <ng-select class="px-0 border-left-0 border-right-0"
                                                placeholder="Building Count Range" formControlName="building_count"
                                                [multiple]="false" [closeOnSelect]="true"
                                                [items]="buildingCountList" bindLabel="title"
                                                [dropdownPosition]="'bottom'" [virtualScroll]="true"
                                                notFoundText="No records found">
                                                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                                    <div class="ng-value"
                                                        *ngFor="let buildingCount of items | slice:0:2">
                                                        <span class="ng-value-label">
                                                            {{buildingCount.title}}
                                                        </span>
                                                        <span class="ng-value-icon right" (click)="clear(buildingCount)"
                                                            aria-hidden="true">×</span>
                                                    </div>
                                                    <div class="ng-value more" *ngIf="items.length > 2">
                                                        <span class="ng-value-label more">{{items.length - 2}}
                                                            more...</span>
                                                    </div>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-12 apt-hide ">
                                    <div class="form-group">
                                        <div class="input-group mb-0">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text" id="basic-addon1">
                                                    <i class="ti ti-home"></i>
                                                </span>
                                            </div>
                                            <ng-select   placeholder="Complex Unit Range"
                                                [searchable]="false" formControlName="unit_range" [closeOnSelect]="true"
                                                [items]="unitRangeList" [dropdownPosition]="'bottom'" bindLabel="title">
                                            </ng-select>
                                        </div>
                                    </div>
                                </div>


                                <!-- <div class="col-lg-12 apt-hide">
                                        <div class="d-flex radio-btn-2">
                                            <h5 class="pt-4">Discovery Status</h5>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio3" name="discoveryStatus"
                                                    class="custom-control-input">
                                                <label class="custom-control-label" for="customRadio3">Yes</label>
                                            </div>
                                            <div class="custom-control custom-radio">
                                                <input type="radio" id="customRadio4" name="discoveryStatus"
                                                    class="custom-control-input">
                                                <label class="custom-control-label" for="customRadio4">NO</label>
                                            </div>
                                        </div>
                                    </div> -->
                                <div class="apt-ffooter">
                                    <div class="w-100">
                                        
                                           
                                            <!-- <button type="button" class="btn btn-default mr-2"
                                                (click)="isCollapsed = !isCollapsed;enableOverlay = false "
                                                [attr.aria-expanded]="!isCollapsed">Close</button> -->
                                                <button type="submit" class="btn btn-primary ">Search</button>
                                            <!-- <button type="button" class="btn btn-reset mr-3" (click)="resetForm()"> Reset</button> -->

                                       
                                    </div>
                                </div>


                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>