import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusName'
})
export class StatusNamePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    let statusName = this.addSpacesToCamelCase(value);
    return this.toTitleCase(statusName);
  }

  toTitleCase(str: string): string {
    return str.toLowerCase().replace(/(?:^|\s|-|'|,)(\w)/g, function(match) {
      return match.toUpperCase();
    });
  }

  addSpacesToCamelCase(input: string): string {
    return input.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
  
}
