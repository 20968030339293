import { Injectable } from '@angular/core';
import { searchDefaultValue } from './apartment.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { ApiService } from '../_core/services/api.service';
import { API_CONFIG } from '../_config'
import { MapGeocoder } from '@angular/google-maps';

@Injectable({
  providedIn: 'root'
})
export class ApartmentService {

  private nearByComplex = new BehaviorSubject(null);
  nearByComplexParam$ = this.nearByComplex.asObservable();

  private zoomLevel = new BehaviorSubject(null);
  zoomLevelParam$ = this.zoomLevel.asObservable();
  // filters object for advanced search
  private filters = new BehaviorSubject(this.payload())
  searchFilters = this.filters.asObservable();

  // list of apartments in map marker and card details page
  public aptList = new BehaviorSubject<[]>([]);
  apartmentList$ = this.aptList.asObservable();

  // list of buildings in map marker and card details page
  public buildings = new BehaviorSubject<[]>([]);
  buildingList$ = this.buildings.asObservable();

  // scroll view for apartments
  public openWindowMarker = new BehaviorSubject<any>(null)
  public hightlightApartment$ = this.openWindowMarker.asObservable();

  public markerPosition = new BehaviorSubject<any>(null)
  public markerPosition$ = this.markerPosition.asObservable();

  public changeMarkerPosition = new BehaviorSubject<any>(null)
  changeMarkerPosition$ = this.changeMarkerPosition.asObservable();

  public taskFilters = new BehaviorSubject<any>(null);
  taskFilters$ = this.taskFilters.asObservable();

  public showList = new BehaviorSubject<any>(true);
  showList$ = this.showList.asObservable();

  public expandList = new BehaviorSubject<any>(false);
  expandList$ = this.expandList.asObservable();

  public complexHistory = new BehaviorSubject<any>(null);
  complexHistory$ = this.complexHistory.asObservable();

  public msoList = new BehaviorSubject<any>(null);
  msoList$ = this.msoList.asObservable();

  public polygonCoordinates = new BehaviorSubject<any>(null);
  polygonCoordinates$ = this.polygonCoordinates.asObservable();

  public locationDetails = new BehaviorSubject<any>(null);
  locationDetails$ = this.locationDetails.asObservable();

  public tags = new BehaviorSubject<any>(null);
  tags$ = this.tags.asObservable();

  public polygonId = new BehaviorSubject<any>(null);
  polygonId$ = this.polygonId.asObservable();

  public accordionStatus = new BehaviorSubject<any>(false);
  accordionStatus$ = this.accordionStatus.asObservable();

  public closeModel = new BehaviorSubject<any>(false);
  closeModel$ = this.closeModel.asObservable();

  public googleAddress = new BehaviorSubject<any>(null);
  googleAddress$ = this.googleAddress.asObservable();

  constructor(
    private apiService: ApiService,
    private geocoder: MapGeocoder,
  ) { }

  payload() {
    const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!) : searchDefaultValue
    return ip
  }
  search(value: any): Observable<any> | any {

    const val = { ...this.filters.value, ...value }
    this.filters.next({ ...val })

    this.apiService.loadSpinner(true);
    return this.apiService.post(API_CONFIG.apartmentAdvancedSearch, val).pipe(
      retry(1),
      map(res => {
        if (res) {
          this.aptList.next(res)
          localStorage.setItem('apartmentPayload', JSON.stringify(val));
          this.apiService.loadSpinner(false);
        } else {
          this.aptList.next([])
          this.apiService.loadSpinner(false);
        }
      }, (err: any) => {
        this.aptList.next([])
        console.error(err);
        this.apiService.loadSpinner(false);
      })
    );

  }

  changenearByComplex(param: any) {
    this.nearByComplex.next(param)
  }

  changezoomLevel(param: any) {
    this.zoomLevel.next(param)
  }

  getAddress(event: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const latlan = 'lat' in event ? event : event.latLng!.toJSON();
      const request: google.maps.GeocoderRequest = {
        location: latlan // Replace with the desired latitude and longitude
      };
      this.geocoder.geocode(request)
      this.geocoder.geocode(request).subscribe((res: any) => {
        resolve(res.results[0].formatted_address)
      })
    });
  }

  isNumeric(str: string): boolean {
    return /^\d+$/.test(str);
  }

  updatePolygonTagName(polygonId: any, tagName: any, state: any) {

    let payload;
    if (this.isNumeric(polygonId)) {
      payload = {
        "query": {
          "term": {
            "properties.building_id": polygonId.toString()
          }
        },
        "script": {
          "source": "ctx._source.properties.manual_tag = params.manual_tag; ctx._source.properties.manual_tag_name = params.manual_tag_name;",
          "lang": "painless",
          "params": {
            "manual_tag": 'yes',
            "manual_tag_name": tagName
          }
        }
      }
    } else {
      payload = {
        "query": {
          "ids": {
            "values": [polygonId]
          }
        },
        "script": {
          "source": "ctx._source.properties.manual_tag = params.manual_tag; ctx._source.properties.manual_tag_name = params.manual_tag_name;",
          "lang": "painless",
          "params": {
            "manual_tag": 'yes',
            "manual_tag_name": tagName
          }
        }
      }
    }
    this.apiService.loadSpinner(true);
    this.apiService.postPolygon(payload, `${state}_buildings_v1/_update_by_query`).subscribe({
      next: (res: any) => {
        this.apiService.loadSpinner(false);
      }, error: any => {
        this.apiService.loadSpinner(false);
      }
    })
  }

  updateNewPolygonTagName(polygonIds: any) {

    const payload = {
      "reportType": "update_polygonindexer",
      "id": polygonIds.map((item: any) => item.replace(/_new/i, '')),
      "approvedFlag": "Y"
    }
    this.apiService.loadSpinner(true);
    this.apiService.postApi(API_CONFIG.polygonTarget, payload).subscribe({
      next: (res: any) => {
        this.apiService.loadSpinner(false);
      }, error: any => {
        this.apiService.loadSpinner(false);
      }
    })
  }

  clearemptyObject(payload: any) {
    for (const key in payload) {
      if (Array.isArray(payload[key])) {
        payload[key] = payload[key].filter((item: any) => item !== "" && item !== "_");
        if (payload[key].length === 0 || payload[key] === "") {
          delete payload[key];
        }
      }
      else if (payload[key] === "") {
        delete payload[key];
      }
    }
    return
  }
}
