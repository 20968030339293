import { Component, OnInit } from '@angular/core';
import { ApiService } from '../_shared';
import { FrameOptions } from 'amazon-quicksight-embedding-sdk/dist/types';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(private api: ApiService) { }

  dashboard: any;

  ngOnInit() {
    this.api.getDashboardUrl().subscribe((data:any) => {
      this.Dashboard(data.body.url)
    });
  }

  async Dashboard(embeddedURL: any) {
    const embeddingContext = await createEmbeddingContext();

    const {
      embedDashboard
    } = embeddingContext;

    const containerDiv = document.getElementById("dashboardContainer") as any;
    const options: FrameOptions = {
      url: embeddedURL,
      container: containerDiv,
      height: "850px",
      width: "100%"
    };
    this.dashboard = await embedDashboard(options);
  }
}