import { Component } from '@angular/core';
import { imagePath } from 'src/app/_config';
import { ApartmentService } from 'src/app/apartment/apartment.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent {
   accessDeniedImage = imagePath.access_denied;
}
