
<div class="main-wrapper" [ngClass]="showSearch ? 'apt-fres': ''">
  <div class="apt-loader" *ngIf="loader">
      <div class="triangles">
          <div class="tri invert"></div>
          <div class="tri invert"></div>
          <div class="tri"></div>
          <div class="tri invert"></div>
          <div class="tri invert"></div>
          <div class="tri"></div>
          <div class="tri invert"></div>
          <div class="tri"></div>
          <div class="tri invert"></div>
      </div>
  </div>
  <app-header *ngIf="currentUser?.email" (showSearchedData)="showSearchedData($event)"></app-header>
  <router-outlet></router-outlet>
  <!-- <app-footer *ngIf="currentUser?.email"></app-footer> -->
  <app-alert></app-alert>
</div>