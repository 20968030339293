import * as defaultSettings from "../_config/default.settings"

export interface searchDetails {
  filters: Filters
  sort: Sort
  page: number
  size: number
}
export interface Filters {
  state: string[]
  city: string[]
  zip: string[]
  county: string[]
  complex_name: string
  search_type: string
  unit_range?: UnitRange
  building_range?: BuildingRange
  // mapped_status: string[],
  mso: string[],
  polygon: any[],
  verification_status: any[],
  approved_status: any[]
}

export interface UnitRange {
  unit_count_from: number
  unit_count_to?: number
}

export interface BuildingRange {
  building_count_from: number,
  building_count_to?: number
}

export interface Sort {
  sort_field?: string
  sort_order?: string
}

export interface BuildingCreate {
  association_id: string
  state: string
  city?: string
  zip?: string
  latitude: number
  longitude: number
  units_count: number
  address: string
  mapped_status: string
  polygon_id: string
  mso?: string
  manual_complex_name: string
  google_address: string
}

export const searchDefaultValue: searchDetails = {
  filters: {
    complex_name: '',
    state: [],
    city: [],
    county: [],
    search_type: defaultSettings.ApartmentModule.searchType,
    zip: [],// [defaultSettings.ApartmentModule.zip],
    // mapped_status: [defaultSettings.ApartmentModule.mappedStatus],
    mso: [],
    polygon: [],   
    verification_status: defaultSettings.ApartmentModule.verification_status,
    approved_status:  defaultSettings.ApartmentModule.approved_status
  },
  sort: {
    sort_field: defaultSettings.ApartmentModule.sortField,
    sort_order: defaultSettings.ApartmentModule.sortOrder
  },
  page: defaultSettings.ApartmentModule.pageNumber,
  size: defaultSettings.ApartmentModule.itemsPerPage
}

export interface Buildings {
  association_id: string
  rwid: string
  s_rwid: string
  open_date: string
  last_checked_date: string
  state: string
  address: string
  city: string
  zip: string
  county: string
  geo_id: string
  latitude: string
  longitude: string
  dma: string
  mso: string
  units_count: number
  mapped_status: string
}

export interface Units {
  association_id: string
  unit: string
  rwid: string
  s_rwid: string
  open_date: string
  last_checked_date: string
  unit_tbl_id: number
  master_apt_name: string
  unit_number: string
  floor_plan: string
  min_rent: string
  max_rent: string
  sqft: string
  bed: string
  bath: string
  full_address: string
  unit_rwid: string
  unit_s_rwid: string
  address: string
  state: string
  city: string
  zip: string
  county: string
  geo_id: string
  latitude: string
  longitude: string
  dma: string
  mso: string
  mapped_status: string
}

export interface complexCreate {
  complex_name: string
  address: string
  open_date: string
  last_checked_date: string
  rwid: string
  state: string
  city: string
  zip: string
  county: string
  latitude: string
  longitude: string
  dma: string
  buildings_count: number
  units_count: number
  active: string
  polygon: string
  polygon_id: string;
  verification_status: string
  reference_url: any
  tags: any
  google_address: string
}

export const statusColorIndication = {
  approved: '#249F2C',
  rejected: '#F0655F',
  pending: '#0285b5',
  new: '#ffa032',
  duplicate: '#390E5D',
  complexLayer:'#E7640D', // add for complex outer layer color
 
};

export const searchDefaultPayload: any = {
  // page: 1,
  // size: 100,
  search: "",
  search_type: "starts_with",
  sort_order: "asc",
  sort_field: "state",
}

export const defaultStatesPayload = {
  "search": "",
  // "page": 1,
  // "size": 100,
  "search_type": "starts_with",
  "sort_order": "asc",
  "sort_field": "state",
}

export const defaultCountiesPayload = {
  "search": "",
  "search_type": "starts_with",
  "state": [],
  "page": 1,
  "size": 100,
  "sort_order": "asc",
  "sort_field": [
    "state",
    "county",
    "city"
  ]
}
export const defaultCitiesPayload = {
  "search": "",
  "search_type": "starts_with",
  "state": [],
  "county": [],
  "page": 1,
  "size": 100,
  "sort_order": "asc",
  "sort_field": [
    "state",
    "city"
  ]
}

export const defaultZipPayload = {
  "search": "",
  "search_type": "starts_with",
  "state": [],
  "county": [],
  "city": [],
  "page": 1,
  "size": 100,
  "sort_order": "asc",
  "sort_field": [
    "state",
    "city",
    "zip"
  ]
}
export class searchPayload {
  constructor(private initialize: any) { }
  getSearchInit() {
    return { ...searchDefaultPayload, ...this.initialize }
  }
}

export class search {
  constructor(private initialize: searchDetails) { }
  getSearchItem() {
    return { ...JSON.parse(localStorage.getItem('apartmentPayload')!), ...this.initialize }
  }
}

export const itemPerPage = [
  {key: 30, label: '30'},
  {key: 60, label: '60'},
  {key: 90, label: '90'},
  {key: 100, label: '100'}
]

export const sortByProperty: any[] = [
  { key: 'complex_name', label: 'Complex Name' },
  { key: 'address', label: 'Complex Address' },
  { key: 'buildings_count', label: 'Building count' },
  { key: 'manual_units_count', label: 'Unit count' },
  // { key: 'association_id', label: 'Association ID'},
  // { key: 'state', label: 'State' }, snce we have single state now
  { key: 'city', label: 'City' },
  { key: 'zip', label: 'Zip Code' },
];

export const buildingVerificationType: any[] = [
  { key: 'noChange', label: 'No Changes' },
  // { key: 'latLon', label: 'Lat Lon' },
  { key: 'verifyAll', label: 'Verification'},
  { key: 'unmapping', label: 'Unmap Building' },
  { key: 'newlyAdded', label: 'New Building' },
  { key: 'units_count', label: 'Units Count' },
  // { key: 'buildingAddress', label: 'Building Address'},
  // { key: 'buildingPolygon', label: 'Add Layer' },
];
 
export const complexVerificationType: any[] = [
  { key: 'noChange', label: 'No Change' },
  // { key: 'complexName', label: 'Complex Name' },
  // { key: 'latLon', label: 'Lat Lon' },
  // { key: 'complexAddress', label: 'Complex Address' },
  { key: 'verifyAll', label: 'Verification'},
  { key: 'tags', label: 'Tags' },
  { key: 'complexPolygon', label: 'Add Layer' },
];

export const polygonData = {
  polygon: null,
  polygonId: null
}

// To get the unmapped building lists
export const unmappedBuildingSearch = {
  "filters" : {
    // "address": "",
    // "search_type" : "starts_with",
    "mapped_status" : [
      "unmapped"
    ],
    "approved_status": [
      "approved","rejected"
    ],
    "range_search" : {
      "latitude" : "",
      "longitude" : "",
      "distance_range" : 10,
      "measuring_unit": "km"
    }
  },
  "sort": {
    "sort_field": "address",
    "sort_order": "asc"
  },
  "page": 1,
  "size": 100
}

export const buildingCreateType = [
  {label: 'New Building', value: 0},
  {label: 'Unmapped Building', value: 1}
]

// For default map marker
export const defaultMapMarker = [
 ]

export const nearByComplexSearc = {
    "filters": {
      "mso": [
        "COMCAST"
      ],
      "state": [
        "fl"
      ],
      "city": [],
      "zip": [],
      "complex_name": "",
      "search_type": "starts_with",
      "approved_status": [
        "approved", "pending", "new"
      ],
      "verification_status": [
        "unverified", "verified"
      ],
      "range_search": {
        "latitude": "27.0381129",
        "longitude": "-82.3940332999",
        "distance_range": 5,
        "measuring_unit": "km"
      }
    },
    "sort": {
      "sort_field": "address",
      "sort_order": "asc"
    },
    "page": 1,
    "size": 20
  }

  export const rangeSearch = {
    "latitude" : "",
    "longitude" : "",
    "distance_range" : 10,
    "measuring_unit": "km"
  }

  export const buildingMap = {
    "association_id" : "",
    "address" : "",
    "latitude" : "",
    "longitude" : "",
    "mapped_status" : "",
    "change_info" : ""
  }
  export const TagList = {
    "search_value": "",
      "search_type": "starts_with",
      "page": 1,
      "size": 15,
      "sort": {
        "sort_field": "tag_name",
        "sort_order": "asc"
      }
  }

  // Status for Report
  export const reportStatus = [
    {label: 'Pending', value: 'pending'},
    {label: 'Approved', value: 'approved'},
    {label: 'Rejected', value: 'rejected'}
  ]

  export const reportType = [
    {label: 'New', value: 'new'},
    {label: 'Existing', value: 'existing'},
  ]

  export const isRwidExist = [
    {label: 'Yes', value: 'present'},
    {label: 'No', value: 'empty'},
  ]

  export const isProcessed = [
    {label: 'Yes', value: 'Y'},
    {label: 'No', value: 'N'},
  ]