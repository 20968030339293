import { Component, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { API_CONFIG } from '../_config/APIPath';
import { allowedGmailDomain, imagePath, loginProvider } from '../_config';
import { ApiService, AuthService } from '../_shared';

import * as $ from "jquery";
import jwt_decode from "jwt-decode";
import { environment } from 'src/environments/environment';
declare let google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements AfterViewInit {

  ellipse = imagePath.login_ellipse;
  logo = imagePath.login_logo;
  googleClientId = environment.googleClientId

  constructor(
    public router: Router,
    private apiService: ApiService,
    private authentication: AuthService,
    private zone: NgZone
  ) {
   
  }
  handleCredentialResponse(res: any) {
    const loginDetails: any = jwt_decode(res.credential);
    const loginPayload: any = {
      name: loginDetails.given_name,
      email: loginDetails.email,
      photoUrl: loginDetails.picture,
      provider: loginProvider
    }
    this.zone.run(() => {
    this.getUser(loginPayload)
      .then((responseData) => {
          // this.router.navigate(['/login']);
          // localStorage.removeItem('apartmentPayload');
          // this.router.navigateByUrl('/search');
          let userDetails: any = jwt_decode(responseData.token);
          // let domain = loginPayload.email.split('@')[1];
          // if(domain == 'sciera.com') {
            userDetails.userImage = loginDetails.picture;
            this.authentication.currentUserSubject.next(userDetails);
            localStorage.setItem('apartment_user', responseData.token);
            localStorage.setItem('apartment_user_image', loginDetails.picture);
          // }else{
          //   this.apiService.alertMessage('danger', 'Important: Application Under Maintenance');
          //   return;
          // }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      });

    if (loginDetails.hd && allowedGmailDomain.indexOf(loginDetails.hd) > -1) {
      $('#hidden-btn').click();
    }
    else {
      $('.text-danger').css('display', 'block');
      setTimeout(() => {
        $('.text-danger').css('display', 'none');
      }, 3000);
    }
  }


  getUser(data: any): Promise<any> {
    this.apiService.loadSpinner(true);
    return new Promise((resolve, reject) => {
      this.apiService.post(API_CONFIG.userLogin, data).subscribe({
        next: (res: any) => {
          if (res != null && res != undefined) {
            this.apiService.loadSpinner(false);
            resolve(res);
          }
        },
        error: (err: any) => {
          console.error(err);
          this.apiService.loadSpinner(false);
        }
      });
    });
  }



  ngAfterViewInit(): void {

    setTimeout(() => {
      this.authentication.currentUserSubject.subscribe((data: any) => {
        if(data != null && 'email' in data){
          this.router.navigateByUrl('/');
        }else{
          google.accounts.id.initialize({
            client_id: this.googleClientId,
            callback: this.handleCredentialResponse.bind(this),
          });
          google.accounts.id.renderButton(
            document.getElementById('google-btn'),
            { theme: 'outline', size: 'large' } // customization attributes
          );
          google.accounts.id.prompt(); // also display the One Tap dialog
        }
      })
    }, 1000)
  }

}