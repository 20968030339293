import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  K_T_C = "8440421a-ebc0-4222-b492-dd9e70261dbf"
  A_UL = environment.awsSecretCdn
  K_UL = environment.awsSecretXapi
  D_D = {"d":environment.DE_ENV };


  constructor(private httpBackend: HttpBackend) { }

  async getConfig() {
    try {
      var http = new HttpClient(this.httpBackend); // Added httpbackend to avoid interceptors
      var headers = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'x-api-key' : this.K_UL
        })
      }
      const resp$ = http.post(this.A_UL, this.D_D, headers);

      var response:any = await lastValueFrom(resp$)
      
      var data:any = await this.d_data(response, this.K_T_C)
      data = JSON.parse(data);
      for(var key in data) {
        let x_asa = await this.d_data(data[key], this.K_T_C)
        environment[key] = x_asa;
      }

      // console.log(environment);
      

      // environment['apiUrl'] = 'https://3572ux477f.execute-api.us-east-1.amazonaws.com/'
 
      return data;
    } catch(error) {
      console.error(error);
    }
  }

  async d_data(E_D: string, A_L: string) {
    var bytes = CryptoJS.AES.decrypt(E_D, A_L);
    var O_T = bytes.toString(CryptoJS.enc.Utf8);
    return O_T;
  }
}
