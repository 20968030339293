import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { authGuard } from './_core/services/auth.guard';
import { AccessDeniedComponent } from './_shared/components/access-denied/access-denied.component';
import { DashboardComponent} from "./dashboard/dashboard.component"
import { TagsComponent } from './tags/tags.component';
import { ReportComponent } from "./report/report.component"
const routes: Routes = [
  { path: '', redirectTo: 'search/list', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard] },
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule), canActivate: [authGuard] },
  { path: 'search', loadChildren: () => import('./apartment/apartment.module').then(m => m.ApartmentModule), canActivate: [authGuard] },
  { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivate: [authGuard] },
  { path: 'roles', loadChildren: () => import('./roles-and-permission/roles-and-permission.module').then(m => m.RolesAndPermissionModule), canActivate: [authGuard] },
  { path: 'task', loadChildren: () => import('./task-management/task-management.module').then(m => m.TaskManagementModule), canActivate: [authGuard] },
  { path: 'tags', component: TagsComponent, canActivate: [authGuard] },
  { path: 'access-denied', component: AccessDeniedComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    bindToComponentInputs: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
