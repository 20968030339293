import { Component } from '@angular/core';

@Component({
  selector: 'app-div-loader',
  templateUrl: './div-loader.component.html',
  styleUrls: ['./div-loader.component.scss']
})
export class DivLoaderComponent {

}
