<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card border-0 apt-table">
                <div class="apt-card-title d-flex justify-content-between mb-2 align-items-center">
                    <div class="card-tash-header d-flex flex-column">
                        <nav aria-label="breadcrumb ">
                            <ol class="breadcrumb bg-white p-0 mb-2">
                                <li class="breadcrumb-item"><a href="javascript:;">Home</a></li>
                                <li class="breadcrumb-item active"><a href="javascript:;">Tags</a></li>
                            </ol>
                        </nav>
                        <h5>Tag List</h5>
                    </div>
                    <div class="card-head-rght d-flex">
                        <div class="input-group">
                            <input pInputText #searchVal class="form-control" type="text"
                                (input)="applyFilterGlobal($event, 'contains')" placeholder="Search Tags" />
                            <div class="input-group-append">
                                <button class="btn btn-success  apt-srch-icon" type="button"><i
                                        class="fe fe-search"></i></button>
                            </div>
                        </div>
                        <button *ngIf="this.currentUser.role_id != 3" title="Create Tag" type="button" class="btn btn-primary ml-3"
                            (click)="createTagname('Create')"><i class="fe fe-plus"></i></button>
                    </div>
                </div>

                <div class="card apt-table-card" >
                    <p-table [scrollHeight]="'calc(100vh - 232px)'" [scrollable]="true" #dt [value]="tagnameList"
                        [paginator]="true" [rows]="rows" [showCurrentPageReport]="true"
                        [tableStyle]="{'min-width': '60rem'}"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        [rowsPerPageOptions]="[15,25,50]" [globalFilterFields]="['tag_name']">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="tag_name" style="width:40%">Tags <p-sortIcon
                                        field="tag_name"></p-sortIcon></th>
                                <th *ngIf="this.currentUser.role_id != 3" pSortableColumn="is_active" style="width:40%">Status <p-sortIcon
                                        field="is_active"></p-sortIcon></th>
                                <th *ngIf="this.currentUser.role_id != 3">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-user>
                            <tr>
                                <td title="{{user.tag_name}}">{{user.tag_name}}</td>
                                <td title="{{user.is_active === 1 ? 'Active' : 'Inactive'}}" *ngIf="this.currentUser.role_id != 3">
                                    <div class="toggle" >
                                        <input type="checkbox" name="toggle" class="toggle-checkbox disabledIcon" [id]="user.id"  [ngClass]="{'disabledIcon': this.currentUser.role_id == 1 ? false : this.currentUser.role_id == 2 && this.currentUser.id == user.created_by ? false : true}" 
                                            [checked]="user.is_active === 1" [appShowElement]="'users_edit'"
                                            [disabled]=" this.currentUser.role_id == 1 ? false :this.currentUser.role_id == 2 && this.currentUser.id == user.created_by ? false : true"
                                            (change)="tagnameStatusUpdate(user, $event)">
                                        <label class="toggle-label" [for]="user.id">
                                            <span class="toggle-inner"></span>
                                            <span class="toggle-switch"></span>
                                        </label>
                                    </div>
                                </td>
                                <td class="apt-table-td-icon"  *ngIf="this.currentUser.role_id != 3" [ngClass]="{'disabledIcon': this.currentUser.role_id == 1 ? false : this.currentUser.role_id == 2 && this.currentUser.id == user.created_by ? false : true}" >
                                    <i title="Edit Tag" class="fe fe-edit apt-table-edit"
                                        (click)="createTagname('Edit',user)"></i></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6" style="text-align:center" class="no-data-found">
                                    <img [src]="noDataImage" width="50" alt=""><br>
                                    <span>No Data Found...</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Create and Edit User Modal -->

<ng-template #userTemplate>
    <div class="add-user-modal">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{ actionType == 'Create' ? 'Add Tag' : 'Edit Tag' }}</h4>
            <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
            </button>
        </div>
        <form [formGroup]="tagnameForm" (ngSubmit)="onSubmit(actionType == 'Create' ? 'Create' : 'Update')" class="apt-form">
        <div class="modal-body pb-0">
          
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <label for="tagName">Tag Name <sup>*</sup></label>
                            <input autocomplete="off" type="text" class="form-control" id="tagName" placeholder="Enter tag name"
                                   formControlName="tagName">
                            <span class="text-danger error-has"
                                  *ngIf="tagnameForm.get('tagName').errors?.required && tagnameForm.get('tagName').touched && !tagnameForm.get('tagName').errors?.minlength">This field is required
                            </span>
                            <span class="text-danger error-has"
                                  *ngIf="tagnameForm.get('tagName').hasError('pattern')">Enter valid name
                            </span>
                            <span class="text-danger error-has"
                                  *ngIf="tagnameForm.get('tagName').hasError('minlength') && tagnameForm.get('tagName').dirty">Enter minimum 5 characters
                            </span>
                            <span class="text-danger error-has"
                                  *ngIf="tagnameForm.get('tagName').hasError('tagExist')">Tag name already exists
                            </span>
                        </div>
                    </div>
                  
                </div>
           
        </div>
        <div class="modal-footer ">
            <div class="row">
                <div class="col-lg-12">
                    <div class=" d-flex justify-content-end add-user-modal-btns">
                        <button type="button" class="btn btn-secondary" (click)="closeModal()">Cancel</button>
                        <button type="submit" class="btn btn-primary ml-3" [disabled]="!tagnameForm.valid"> {{
                            actionType == 'Create' ? 'Submit' : 'Update' }}</button>
                    </div>
                </div>
            </div>
          
        </div>
    </form>
    </div>
</ng-template>