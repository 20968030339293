import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ApiService, AuthService } from '../_shared';
import * as moment from "moment"
import { API_CONFIG, imagePath } from 'src/app/_config';
import { Chart } from 'angular-highcharts';
import _, { zip } from 'lodash';
import { UsersService } from "../users/users.service"
import { defaultSearchPayload } from 'src/app/users/users.model';
import { TaskValues, defaultCitiesPayload, defaultReportPayload, defaultStatesPayload, defaultZipPayload } from '../task-management/task.model';
import { Table } from 'primeng/table';
import { GeoService } from '../_core/services/geo.service';
import { debounceTime, Subject } from 'rxjs';
import { defaultCountiesPayload, reportStatus, reportType, statusColorIndication } from '../apartment/apartment.model';
import * as defaultSettings from "../_config/default.settings";
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent implements OnInit {
  @ViewChild('dropdownMenu') dropdownMenu!: ElementRef;

  isDropdownOpen = false;
  selectedDateType: any = 'Current week';
  datelist: any = ['Today', 'Yesterday', 'Previous week', 'Current week', 'Date Range'];
  reportForm!: FormGroup;
  fieldName: any = 'complex';
  cities = []
  columnChart: any;
  columnUniqueChart: any;
  userDetails: any;
  recentlyAddedList: any;
  uniqueDetailList: any;
  frequency: string = 'Today'
  defaultSelectedVal: any;

  dataOperators: any = [];
  usersDataStore: Array<[]> = [];
  users: any = [];
  usersLists: Array<[]> = []
  reportByUser: Array<[]> = []

  @ViewChild('dt') dt: Table | any;
  rows  =10;
  states: any = [];
  zips: any = [];
  isloading: any = {};
  selectedCities:any = null;
  selectedZip:any = null;
  searchCityList: any = [];  
  countySearchList: any = [];
  countyList: any = [];
  filterHasValue = false;
  searchVal: any;
  noDataImage = imagePath.no_data_found;
  first: any = TaskValues.first;
  pageSize: number = 10;
  totalCount: any;
  changesLoader:boolean=false
  chartLoader:boolean=false
  @ViewChild('datepickerInput') datepickerInput!: ElementRef;
  showDateRange = false;
  searchTerm$ = new Subject<string>();
  reportStatus = reportStatus;
  reportType = reportType;
  today: Date = new Date();
  isUniqueCountOn: boolean = false;
  change_info: any = '';
  selectedStatus: any = [
    "pending",
    "approved",
    "rejected"
  ];
  filterOverlay = false;
  chartStatusColor:any = statusColorIndication
  noRecordFound = defaultSettings.imagePath.empty_box;
  noGraphData = false; 
  reportpayload: any = defaultReportPayload;
  
  constructor(
    private apiService: ApiService,
    private authentication: AuthService,
    private usersService: UsersService,
    private geoService: GeoService,
    private eRef: ElementRef,
    private renderer: Renderer2,
    private formbuilder: FormBuilder
  ) {
    this.authentication.currentUserSubject.subscribe((data: any) => {
      if (data) {
        this.userDetails = data;
        if (data.role_id > 1) this.reportByUser.push(data.email)
      }
    })
  }

  toggleDropdown(event: Event) {
    this.isDropdownOpen = !this.isDropdownOpen;
    if(!this.isDropdownOpen)
    event.stopPropagation();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    if (!this.eRef.nativeElement.contains(event.target) && this.isDropdownOpen) {
      this.isDropdownOpen = false;
    }
  }
  applyFilterGlobal($event: any, stringVal: any) {
    this.searchVal = $event.target.value;
    this.searchTerm$.next(this.searchVal);
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  onFilterReport() {
    this.first = 0;
    if (this.isUniqueCountOn) {
      this.getUniqueData();
      this.getUniqueDetailReport('isStatus');
    } else {
      this.getRecentList('isStatus');
      this.getAllStatus();
    }
  }
  
  onFilterSelect(filterType: string, events: any = null) {

    if (filterType == 'complex' || filterType == 'building') {
      this.fieldName = filterType
    } else if (filterType.toLowerCase() == 'today') {
      this.reportForm.get('startDate')?.setValue(moment().format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().format('YYYY-MM-DD') + ' - ' + moment().format('YYYY-MM-DD');
      this.frequency = 'TODAY';
    } else if (filterType.toLowerCase() == 'yesterday') {
      this.reportForm.get('startDate')?.setValue(moment().subtract(1, 'days').format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().subtract(1, 'days').format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().subtract(1, 'days').format('YYYY-MM-DD') + ' - ' + moment().subtract(1, 'days').format('YYYY-MM-DD');
      this.frequency = 'YESTERDAY';
    } else if (filterType.toLowerCase() == 'last7days') {
      this.reportForm.get('startDate')?.setValue(moment().subtract(7, 'days').format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().subtract(1, 'days').format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().subtract(7, 'days').format('YYYY-MM-DD') + ' - ' + moment().subtract(7, 'days').format('YYYY-MM-DD');
      this.frequency = 'LAST7DAYS';
    } else if (filterType.toLowerCase() == 'last30days') {
      this.reportForm.get('startDate')?.setValue(moment().subtract(29, 'days').format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().subtract(29, 'days').format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().subtract(29, 'days').format('YYYY-MM-DD') + ' - ' + moment().subtract(29, 'days').format('YYYY-MM-DD');
      this.frequency = 'LAST30DAYS';
    } else if (filterType.toLowerCase() == 'thismonth') {
      this.reportForm.get('startDate')?.setValue(moment().startOf('month').format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().endOf('month').format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().startOf('month').format('YYYY-MM-DD') + ' - ' + moment().endOf('month').format('YYYY-MM-DD');
      this.frequency = 'CURRENTMONTH';
    } else if (filterType.toLowerCase() == 'lastmonth') {
      this.reportForm.get('startDate')?.setValue(moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'));
      this.defaultSelectedVal = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD') + ' - ' + moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
      this.frequency = 'LASTMONTH';
    } else if (filterType.toLowerCase() == 'daterange') {
      this.reportForm.get('startDate')?.setValue(moment(events[0]).format('YYYY-MM-DD'));
      this.reportForm.get('endDate')?.setValue(moment(events[1]).format('YYYY-MM-DD'));
      this.frequency = 'DATERANGE';
      this.defaultSelectedVal = moment(events[0]).format('YYYY-MM-DD') + ' - ' + moment(events[1]).format('YYYY-MM-DD');
    } 
    if (this.isUniqueCountOn) {
      this.getUniqueData();
      this.getUniqueDetailReport();
    } else {
      this.getRecentList();
      this.getAllStatus();
    }
  }

  getFilteredData() {
    const selectedUser = this.reportForm.get('selectedUser')?.value;
    const selectedDataOperator = this.reportForm.get('selectedDataOperator')?.value;
    this.reportByUser = selectedUser?.email ? [selectedUser.email] :(selectedDataOperator?.email) ? [selectedDataOperator?.email]: []; 
    if (this.isUniqueCountOn) {
      this.getUniqueData();
      this.getUniqueDetailReport();
    } else {
      this.getRecentList();
      this.getAllStatus();
    }
    this.filterOverlay = false;
  }

  getDateList(start: any, end: any) {
    let daysArray: string[] = [];

    let currentDate = moment(start);
    while (currentDate.isSameOrBefore(end)) {
      daysArray.push(currentDate.format('YYYY-MM-DD'));
      currentDate.add(1, 'day');
    }
    return daysArray
  }

  capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  
  changeUniqueMode(event: any) {
    this.isUniqueCountOn = event.target.checked;
    if (this.isUniqueCountOn) {
      this.getUniqueData();
      this.getUniqueDetailReport();
    } else {
      this.getRecentList();
      this.getAllStatus();
    }
  }

  getAllStatus() {
    
    this.reportpayload.user = this.reportByUser;
    this.reportpayload['filters']['date_range'].date_from = this.reportForm.get('startDate')?.value;
    this.reportpayload['filters']['date_range'].date_to = this.reportForm.get('endDate')?.value;
    this.reportpayload['filters']['change_info'] = this.reportForm.get('change_info')?.value ? this.reportForm.get('change_info')?.value: '';
    this.reportpayload['filters']['approved_status'] = this.reportForm.get('approved_status')?.value.length > 0 ?  this.reportForm.get('approved_status')?.value : this.selectedStatus;
     
    let APIPATH
    if(this.reportForm.get('state')?.value) {
      this.reportpayload.filters.state = [this.reportForm.get('state')?.value]
    }
     if(this.reportForm.get('county')?.value && this.reportForm.get('county')?.value.length > 0) {
      this.reportpayload.filters.county = this.reportForm.get('county')?.value
    }
    if(this.reportForm.get('city')?.value && this.reportForm.get('city')?.value.length > 0) {
      this.reportpayload.filters.city = this.reportForm.get('city')?.value
    }
    if(this.reportForm.get('zip')?.value && this.reportForm.get('zip')?.value.length > 0) {
      this.reportpayload.filters.zip = this.reportForm.get('zip')?.value
    }

    APIPATH = this.fieldName == "complex" ? API_CONFIG.complexReport : API_CONFIG.buildingReport
    this.chartLoader = true;
    this.apiService.post(APIPATH, this.reportpayload).subscribe(async (report: any) => {      
      this.apiService.loadSpinner(false);
      let widgets = report.widgets
      let statuses = ['pending', 'approved', 'rejected'];

      if(widgets && widgets.length > 0) {

        let dataSeriess:any = statuses.map(status => ({
          name: this.capitalizeFirstLetter(status),
          data: widgets.map((widget:any) => widget[status]),
          color: this.chartStatusColor[status] ? this.chartStatusColor[status] : null
        }));
        // Initialize the chart
        this.columnChart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            categories: widgets.map((widget: any) => widget.verified_on),
            crosshair: true
          },
          yAxis: {
            title: {
              text: 'No of request raised'
            }
          },
          tooltip: {
            shared: true
          },
          series: dataSeriess
        });
        this.noGraphData = false;
      } else {
        this.noGraphData = true;
      }
      this.chartLoader = false;
    },err=>{
      this.apiService.loadSpinner(false);
      this.chartLoader = false;
    });
  }
  
  getUniqueData() {
    this.reportpayload.user = this.reportByUser;
    this.reportpayload['filters']['date_range']['date_from'] = this.reportForm.get('startDate')?.value;
    this.reportpayload['filters']['date_range'].date_to = this.reportForm.get('endDate')?.value;
    this.reportpayload['filters']['change_info'] = this.reportForm.get('change_info')?.value ? this.reportForm.get('change_info')?.value: '';
    this.reportpayload['filters']['approved_status'] =  this.reportForm.get('approved_status')?.value.length > 0 ?  this.reportForm.get('approved_status')?.value : this.selectedStatus;
    
    let APIPATH
    if(this.reportForm.get('state')?.value) {
      this.reportpayload.filters.state = [this.reportForm.get('state')?.value]
    }
     if(this.reportForm.get('county')?.value && this.reportForm.get('county')?.value.length > 0) {
      this.reportpayload.filters.county = this.reportForm.get('county')?.value
    }
    if(this.reportForm.get('city')?.value && this.reportForm.get('city')?.value.length > 0) {
      this.reportpayload.filters.city = this.reportForm.get('city')?.value
    }
    if(this.reportForm.get('zip')?.value && this.reportForm.get('zip')?.value.length > 0) {
      this.reportpayload.filters.zip = this.reportForm.get('zip')?.value
    }
    
    // return
    APIPATH = this.fieldName == "complex" ? API_CONFIG.complexUniqueReport : API_CONFIG.buildingUniqueReport
    this.chartLoader = true;
    this.apiService.post(APIPATH, this.reportpayload).subscribe(async (report: any) => {      
      this.apiService.loadSpinner(false);
      let widgets = report.widgets
      let statuses = ['pending', 'approved', 'rejected'];

      let segmentDataSeries: any[] = [];
      if(widgets && widgets.length > 0) {

        let dataSeriess:any = statuses.map(status => ({
          name: this.capitalizeFirstLetter(status),
          data: widgets.map((widget:any) => widget[status]),
          color: this.chartStatusColor[status] ? this.chartStatusColor[status] : null
        }));
        // Initialize the chart
        this.columnUniqueChart = new Chart({
          chart: {
            type: 'column'
          },
          title: {
            text: ''
          },
          credits: {
            enabled: false
          },
          xAxis: {
            categories: widgets.map((widget: any) => widget.verified_on),
            crosshair: true
          },
          yAxis: {
            title: {
              text: 'No of request raised'
            }
          },
          tooltip: {
            shared: true
          },
          series: dataSeriess
        });
        this.noGraphData = false;
      } else {
        this.noGraphData = true;
      }
 
      this.chartLoader = false;
    },err=>{
      this.apiService.loadSpinner(false);
      this.chartLoader = false;
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    });
  }

  getRecentList(type?:any, event?:any) {
    
    this.reportpayload.user = this.reportByUser;
    this.reportpayload['filters']['date_range']['date_from'] = this.reportForm.get('startDate')?.value;
    this.reportpayload['filters']['date_range'].date_to = this.reportForm.get('endDate')?.value;
    this.reportpayload['filters']['change_info'] = this.reportForm.get('change_info')?.value ? this.reportForm.get('change_info')?.value: '';
    this.reportpayload['filters']['approved_status'] = this.reportForm.get('approved_status')?.value.length > 0 ?  this.reportForm.get('approved_status')?.value : this.selectedStatus;

    if(this.reportForm.get('state')?.value) {
      this.reportpayload.filters.state = [this.reportForm.get('state')?.value]
    }
     if(this.reportForm.get('county')?.value && this.reportForm.get('county')?.value.length > 0) {
      this.reportpayload.filters.county = this.reportForm.get('county')?.value
    }
    if(this.reportForm.get('city')?.value && this.reportForm.get('city')?.value.length > 0) {
      this.reportpayload.filters.city = this.reportForm.get('city')?.value
    }
    if(this.reportForm.get('zip')?.value && this.reportForm.get('zip')?.value.length > 0) {
      this.reportpayload.filters.zip = this.reportForm.get('zip')?.value
    }

    if (type === 'isSearch') {
      this.reportpayload.filters['search_value'] = this.searchVal;
      this.first = 0;
      if (this.searchVal === '') {
        this.reportpayload['page'] = 1;
        this.reportpayload['size'] = this.pageSize;
        this.first = 0;
      }
    } else if (type === 'pagination' || type === 'searchPage') {
      if(type == 'pagination') this.reportpayload['page'] = event.page;
      else this.reportpayload['page'] = 1;
      // this.reportpayload['page'] = type === 'pagination' ? event.page : 1;
      this.reportpayload['size'] = this.pageSize;
      if (type === 'searchPage') {
        this.reportpayload.filters['search_value'] = this.searchVal;
      }
    }
    // return
    this.loadData(this.reportpayload);
  }

  getUniqueDetailReport(type?:any, event?:any) {
    
      this.reportpayload.user = this.reportByUser;
      this.reportpayload['filters']['date_range']['date_from'] = this.reportForm.get('startDate')?.value;
      this.reportpayload['filters']['date_range'].date_to = this.reportForm.get('endDate')?.value;
      this.reportpayload['filters']['change_info'] = this.reportForm.get('change_info')?.value ? this.reportForm.get('change_info')?.value: '';
      this.reportpayload['filters']['approved_status'] = this.reportForm.get('approved_status')?.value.length > 0 ?  this.reportForm.get('approved_status')?.value : this.selectedStatus;
  
      if(this.reportForm.get('state')?.value) {
        this.reportpayload.filters.state = [this.reportForm.get('state')?.value]
      }
       if(this.reportForm.get('county')?.value && this.reportForm.get('county')?.value.length > 0) {
        this.reportpayload.filters.county = this.reportForm.get('county')?.value
      }
      if(this.reportForm.get('city')?.value && this.reportForm.get('city')?.value.length > 0) {
        this.reportpayload.filters.city = this.reportForm.get('city')?.value
      }
      if(this.reportForm.get('zip')?.value && this.reportForm.get('zip')?.value.length > 0) {
        this.reportpayload.filters.zip = this.reportForm.get('zip')?.value
      }
  
      if (type === 'isSearch') {
        this.reportpayload.filters['search_value'] = this.searchVal;
        this.first = 0;
        if (this.searchVal === '') {
          this.reportpayload['page'] = 1;
          this.reportpayload['size'] = this.pageSize;
          this.first = 0;
        }
      } else if (type === 'pagination' || type === 'searchPage') {
        if(type == 'pagination') this.reportpayload['page'] = event.page;
        else this.reportpayload['page'] = 1;
        // this.reportpayload['page'] = type === 'pagination' ? event.page : 1;
        this.reportpayload['size'] = this.pageSize;
        if (type === 'searchPage') {
          this.reportpayload.filters['search_value'] = this.searchVal;
        }
      }
      // return
      this.loadUniqueData(this.reportpayload);
  }

  loadUniqueData(payload: any) {
    let APIPATH = this.fieldName == "complex" ? API_CONFIG.complexUniqueDetailReport : API_CONFIG.buildingUniqueDetailReport;
    this.changesLoader = true;
    this.apiService.post(APIPATH, payload).subscribe((report: any) => {
      this.changesLoader = false;
      const recentList = this.fieldName == "complex" ? report.complexes : report.buildings;
      if (recentList) {
        this.uniqueDetailList = recentList.map((item: any) => {
          const matchingUser: any = this.usersDataStore.find((user: any) => user.email === item.requested_by);
          const approvedUser: any = this.usersDataStore.find((user: any) => user.email === item.approved_by);
  
          if (matchingUser) {
            item.requested_by_name =  matchingUser ? `${matchingUser.first_name} ${matchingUser.last_name}`: '-';
          }
          if (approvedUser) {
            item.approved_by_name = approvedUser ? `${approvedUser.first_name} ${approvedUser.last_name}`: '-';
          }
  
          return item;
        });
      }
      
      this.totalCount = report.total;
    }, err => {
      this.changesLoader = false;
      if('error_code' in err && err['error_code'] != 0) {
        this.apiService.alertMessage('danger', err['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    });
  }

  loadData(payload: any) {
    let APIPATH = this.fieldName == "complex" ? API_CONFIG.recentComplexReport : API_CONFIG.recentBuildingReport;
    this.changesLoader = true;
    this.apiService.post(APIPATH, payload).subscribe((report: any) => {
      this.changesLoader = false;
      const recentList = this.fieldName == "complex" ? report.complexes : report.buildings;
      if (recentList) {
        this.recentlyAddedList = recentList.map((item: any) => {
          const matchingUser: any = this.usersDataStore.find((user: any) => user.email === item.requested_by);
          const approvedUser: any = this.usersDataStore.find((user: any) => user.email === item.approved_by);
  
          if (matchingUser) {
            item.requested_by_name =  matchingUser ? `${matchingUser.first_name} ${matchingUser.last_name}`: '-';
          }
          if (approvedUser) {
            item.approved_by_name = approvedUser ? `${approvedUser.first_name} ${approvedUser.last_name}`: '-';
          }
  
          return item;
        });
      }
      
      this.totalCount = report.total;
    }, err => {
      this.changesLoader = false;
      if('error_code' in err && err['error_code'] != 0) {
        this.apiService.alertMessage('danger', err['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    });
  }
  
  // To get Users List
  getUsersList() {
    const payload = defaultSearchPayload;
    try {
      this.usersLists = []
      this.dataOperators = []
      this.usersDataStore = []
      this.usersService.getUsersList({}).subscribe((res: any) => {

        if ('users' in res) {
          // this.apiService.loadSpinner(false);
          // this.usersList = res.users
          // this.storeList = res.users
        }
        // else this.apiService.alertMessage('danger', 'Failed to get Users Lists')
      })
    } catch (e:any) {
      if('error_code' in e && e['error_code'] != 0) {
        this.apiService.alertMessage('danger', e['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    }

  }

  // On Geo Search
  onSearch(searchTerm: any, type: string) {
    if (searchTerm && 'term' in searchTerm) {
      if (type == 'state') this.getStates(searchTerm)
      if (type == 'city') this.getCities(searchTerm, 'search')
      if (type == 'zip') this.getZips(searchTerm, 'search')
    } else if (searchTerm.term == '') {
      // if (type == 'state') this.getStates()
      // if (type == 'city') this.getCities()
      // if (type == 'zip') this.getZips()
    }
  }

  toggleDateRange() {
    this.renderer.selectRootElement(this.datepickerInput.nativeElement).click();
  }

  // To get states
  getStates(searchTerm?:any) {
    try {
      this.states = [];
      this.cities = [];
      this.isloading['state'] = true;
      if (searchTerm && 'term' in searchTerm && searchTerm.term != '') {
        defaultStatesPayload['search'] = searchTerm.term;
      } else defaultStatesPayload['search'] = '';
      this.geoService.getGeography('', API_CONFIG.getStates, defaultStatesPayload).subscribe((response: any) => {
        if (response && 'states' in response) {
          this.states = response['states'];
          this.isloading['state'] = false;
        }
      });
    } catch(error:any) {
      if('error_code' in error && error['error_code'] != 0) {
        this.apiService.alertMessage('danger', error['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    }
  }

  // To get Counties
  async getCounties(searchTerm?: any): Promise<void> {
    this.countySearchList = [];
    let stateData: any = this.reportForm.get('state')?.value;;
    let payload: any = defaultCountiesPayload;
    if (!stateData?.length ) {
      this.cities = [];
      this.countySearchList = [];
      this.countyList  =[]
      this.zips = [];
      this.reportForm.get('county')?.setValue([]);
      this.reportForm.get('city')?.setValue([]);
      this.reportForm.get('zip')?.setValue([]);
    }

    if (stateData?.length) { // If any state selected
      payload['state'] = [stateData];
    } 
    if (searchTerm && 'term' in searchTerm && searchTerm.term != '') {
      payload['search'] = searchTerm.term;
    } else payload['search'] = '';
    this.isloading['counties'] = true;

    try {
      const response = await this.geoService.getGeography('', API_CONFIG.getCounty, payload).toPromise();
      if (response && 'counties' in response) {
        this.isloading['counties'] = false;
        if (payload['search'] != '') this.countySearchList = response['counties']
        else this.countyList = response['counties'];
        this.validateSecondLevel('county', response['counties'])
      }
      return Promise.resolve(response);
    } catch (error: any) {
      this.isloading['counties'] = false;
      if ('error_code' in error && error['error_code'] != 0) {
        this.apiService.alertMessage('danger', error['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
      // console.error('Error fetching cities:', error);
      // Handle error as needed
    }
  }

  // To get cities
  getCities(searchTerm?: any, type?: string, page = 1) {
    try {
      let stateData = this.reportForm.get('state')?.value;
      let countyData = this.reportForm.get('county')?.value;
      let cityData = this.reportForm.get('city')?.value;
      if (!stateData?.length) {
        this.cities = [];
        this.countySearchList = [];
        this.countyList = [];
        this.zips = [];
        this.reportForm.get('city')?.setValue([]);
        this.reportForm.get('zip')?.setValue([]);
        if (!cityData?.length) {
          this.zips = [];
        }
      } else {
        // this.taskForm.get('city').enable();
        if (stateData.length) { // If any state selected
          defaultCitiesPayload['state'] = [stateData];
        }
        if (countyData.length) {  
          defaultCitiesPayload['county'] = countyData;
        }
        if (searchTerm && 'term' in searchTerm && searchTerm.term != '') {
          defaultCitiesPayload['search'] = searchTerm.term;
        } else defaultCitiesPayload['search'] = '';
        defaultCitiesPayload.page = page
        this.isloading['cities'] = true;
        this.geoService.getGeography('', API_CONFIG.getCities, defaultCitiesPayload).subscribe((response) => {
          if (response && 'cities' in response) {
            // If there is search term means, Assign the city to the new variable for restoring original array while focus out
            if (defaultCitiesPayload['search'] != '') {
              this.searchCityList = response['cities']
                .filter((val: any) => val.city !== '')  // Remove elements where city is empty
                .map((val: any) => {
                  val['city_control'] = `${val.state}_${val.city}`;
                  val['city_label'] = `${val.city} (${val.state_full_name})`;
                  return val;
                });
            } else {
              this.cities = response['cities']
                .filter((val: any) => val.city !== '')  // Remove elements where city is empty
                .map((val: any) => {
                  val['city_control'] = `${val.state}_${val.city}`;
                  val['city_label'] = `${val.city} (${val.state_full_name})`;
                  return val;
                });
            }
            if (type != 'search') this.validateSecondLevel('city', response['cities'])
            this.isloading['cities'] = false;
          }
        })
      }
    } catch (error: any) {
      if ('error_code' in error && error['error_code'] != 0) {
        this.apiService.alertMessage('danger', error['message']);
        return;
      }
      this.apiService.alertMessage('danger', 'Something went wrong, Please try again!');
    }
  }

  // TO get zips
  getZips(searchTerm?: any, type?: string, page = 1) {
    try {
      this.searchCityList = [];
      let stateData = this.reportForm.get('state')?.value;
      let countyData = this.reportForm.get('county')?.value;
      let cityData = this.reportForm.get('city')?.value;

      if (!cityData?.length) {
        this.zips = [];
        this.reportForm.get('zip')?.setValue([])
      } else {
        if (stateData && cityData) {
          defaultZipPayload['state'] = [stateData];
          defaultZipPayload['city'] = cityData;
        } 
        if(countyData) {
          defaultZipPayload['county'] = countyData
        }
        if (searchTerm && 'term' in searchTerm && searchTerm.term != '') {
          defaultZipPayload['search'] = searchTerm.term;
        } else defaultZipPayload['search'] = '';
        defaultZipPayload.page = page;
        this.isloading['zips'] = true;
        this.geoService.getGeography('', API_CONFIG.getZips, defaultZipPayload).subscribe((response) => {
          if (response && 'zips' in response) {
            this.zips = response['zips'];
            if (type != 'search') this.validateSecondLevel('zip', response['zips']);
            this.isloading['zips'] = false;
          }
        })
      }
    } catch (error: any) {
      if ('error_code' in error && error['error_code'] != 0) {
        this.apiService.alertMessage('danger', error['message']);
        return;
      }
      this.apiService.alertMessage('error', 'Something went wrong, Please try again!');
    }
  }

  onZipSelection() {
    if (this.isUniqueCountOn) {
      this.getUniqueData();
      this.getUniqueDetailReport();
    } else {
      this.getRecentList();
      this.getAllStatus();
    }  
  }

  validateSecondLevel(field: any, vals: any) {

    let argVal;
    if(field == 'county') { 
      argVal = this.reportForm.get('county')?.value
    } else if(field == 'city') {
      argVal = this.reportForm.get('city')?.value
    } else argVal = this.reportForm.get('zip')?.value
    let list = vals.map((el: any) => {
      if (field == 'county') return el.county
      if (field == 'city') return el.city_control
      if (field == 'zip') return el.zip
    });
    if (argVal != '') {

      let argVals = argVal?.filter((ele: any) => {

        if (list.indexOf(ele) > -1) return ele
      })
      if(field == 'county') {
        this.reportForm.get('counties')?.setValue(argVals);
      } else if(field == 'city') {
        this.reportForm.get('city')?.setValue(argVals);
        this.getZips();
      } 
      else this.reportForm.get('zip')?.setValue(argVals);
    } 
  }

  getUserbyDataOperator() {
    const selectedDataOperator = this.reportForm.get('selectedDataOperator')?.value;
     if(selectedDataOperator) {
       this.users = this.usersLists.filter((res: any) => {
         return res.parent_id === selectedDataOperator.id;
       });
     } else {
      this.loadUser();
     }
  }

  checkFilterValues() {
    this.filterOverlay = true;
    const state = this.reportForm.get('state')?.value;
    const city = this.reportForm.get('city')?.value;
    const zip = this.reportForm.get('zip')?.value;
    const selectedUser = this.reportForm.get('selectedUser')?.value;
    const selectedDataOperator = this.reportForm.get('selectedDataOperator')?.value;
    this.filterHasValue = (!!state && state?.length > 0) || (!!city && city?.length > 0) || (!!zip && zip?.length > 0) || (!!selectedDataOperator) || (!!selectedUser);
  }

  // On Pagination Change
  onPageChange(event: any) {
    if (this.isUniqueCountOn) {
      event.page = event.page + 1; // Increasing Page count
      this.first = event.first;
      if (this.searchVal != '' && this.searchVal != undefined) this.getUniqueDetailReport('searchPage', event)
      else this.getUniqueDetailReport('pagination', event)
    } else {
      event.page = event.page + 1; // Increasing Page count
      this.first = event.first;
      if (this.searchVal != '' && this.searchVal != undefined) this.getRecentList('searchPage', event)
      else this.getRecentList('pagination', event)
    }
  }

  loadUser() {
    this.usersService.userLists.subscribe((user: any) => {
      if (Array.isArray(user)) {
        this.usersDataStore = user
        if (this.userDetails.role_id == 2) {
          this.dataOperators = this.users.filter((value: any) => value.parent_id == this.userDetails.id);
        }
        this.usersLists = user.filter((value: any) => value.role_id === 3 && value.is_active == 1);
        this.users = user.filter((value: any) => value.role_id === 3 && value.is_active == 1);
        if (TaskValues.listAllUserToAdmin) {
          this.dataOperators = user.filter((value: any) => value.role_id === 2 && value.is_active == 1);
        } else {
          this.dataOperators = user.filter((value: any) => value.role_id === 2 && this.userDetails.id == value.parent_id && value.is_active == 1);
        }
      } else {
        this.getUsersList();
      }
    })
  }

  formInit() {
    this.reportForm = this.formbuilder.group({
      startDate: [''],
      endDate: [''],
      state: [null],
      county: [''],
      city: [''],
      zip: [''],
      uniqueCountOn: [false],
      change_info: [null],
      approved_status: [[]],
      selectedDataOperator: [null],
      selectedUser: [null]
    });
  }

  ngOnInit() {
    this.formInit();
    this.onFilterSelect('last7days');
    this.getStates();
    this.loadUser();

    // Trigger the api while grid search in debounce
    this.searchTerm$.pipe(
      debounceTime(300)
    ).subscribe(() => {
      if (this.isUniqueCountOn) {
        this.getUniqueDetailReport('isSearch');
      } else {
        this.getRecentList('isSearch');
      }
    });
  }

}