import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToString'
})
export class ArrayToStringPipe implements PipeTransform {

  transform(value: any, geoType: string): any {
    let returnValue ='';
    // console.log(value, geoType);
    if(geoType == 'county') {
      returnValue = value.map((item: any) => item.county_label).join(', ');
    } else if (geoType == 'city') {
      returnValue = value.map((item: any) => item.city_label? item.city_label:  item).join(', ');
    } else if (geoType == 'zip') {
      returnValue = value.map((item: any) => item.zip ? item.zip : item).join(', ');
    } else if (geoType == 'mso') {
      returnValue = value.map((item: any) => item).join(', ');
    }
    return returnValue;
  }

}
