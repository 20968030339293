import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service'
import { Router } from '@angular/router';
import * as moment from "moment"
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  alert = new BehaviorSubject([]);
  loader = new BehaviorSubject(false);
  dataStore: any[] = [];
  baseUrl = environment.baseUrl;
  quickSightUrl:any = environment.quicksightUrl
  xApiKeyQuickSight = environment.x_api_key_quicksight
  polygonUrl = environment.polygon_endpoint;
  polygonApiKey = environment.x_api_key_polygon;
  polygonManualIndexing = environment.polygonManualIndexing;
  x_api_key_manual_polygon = environment.x_api_key_manual_polygon;
  public apartment_list = new BehaviorSubject<any[]>([]);
  readonly datas = this.apartment_list.asObservable();
  currentUser: any


  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': `bearer ${localStorage.getItem('apartment_user') || 'string'}`
      })
    }
  }

  dashboardId = {
    "dashboardId": environment.quiksightDashboardId
  };

  constructor(
    public router: Router,
    private http: HttpClient,
    private authentication: AuthService
  ) {
    this.authentication.currentUserSubject.subscribe((data: any) => {
      this.currentUser = null
      if (data) {
        this.currentUser = (data);

        if (!Object.prototype.hasOwnProperty.call(this.currentUser, 'email')) {
          this.router.navigateByUrl('/');
        } else if ('exp' in data) { // Check API token expiration and redirect to login page

          if (moment().isAfter(Number(data.exp + '000'), 'minute')) {
            this.clearStorage();
            this.authentication.currentUserSubject.next(null)
            this.router.navigateByUrl('/');
          }
        }
      }
    })

  }

  getDashboardUrl(): Observable<any> {
    return this.http.post(this.quickSightUrl, this.dashboardId, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "X-Api-Key": this.xApiKeyQuickSight
      })
    });
  }

  loadSpinner(value: any) {
    this.loader.next(value);
  }

  //Alert Message value change
  alertMessage(typ: any, msgs: any) {
    const alrt: any = {
      type: typ,
      msg: msgs
    }
    this.alert.next(alrt)
  }

  postS(data: any) {
    try {
      this.dataStore = [];
      this.dataStore.push(data);
      this.apartment_list.next(this.dataStore);
    } catch (e) {
      this.alertMessage("danger", e);
    }

  }

  update(data: any, id: any) {
    try {
      const foundIndex = this.dataStore.findIndex((x: any) => x.id == id);
      this.dataStore[foundIndex] = data;
      this.apartment_list.next(this.dataStore);
    } catch (e) {
      this.alertMessage("danger", e);
    }
  }

  delete(url: any): Observable<any> {
    return this.http.delete(this.baseUrl + url, this.httpOptions()).pipe(
      catchError(this.handleError)
    );
  }

  post(url: any, input: any): Observable<any> {
    const headerObj = url == 'users/login' ? { headers: new HttpHeaders({}) } : this.httpOptions()
    return this.http.post(this.baseUrl + url, input, headerObj).pipe(
      catchError((error) => {
        if(error.error.error_code == 422) {
          return throwError(error.error)
        }
        this.handleError(error); // Log and handle the error
        return throwError(error);
      })
    );
  }

  postPolygon(input: any, url: string): Observable<any> {
    return this.http.post(this.polygonUrl + url, input, {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": "Basic " + this.polygonApiKey
      })
    });
  }

  put(url: any, input: any): Observable<any> {
    return this.http.put(this.baseUrl + url, input, this.httpOptions()).pipe(
      catchError((error) => {
        if(error.error.error_code == 422) {
          return throwError(error.error)
        }
        this.handleError(error); // Log and handle the error
        return throwError(error);
      })
    );
  }

  get(url: any, params?: any): Observable<any> {
    let httpOptions: any = this.httpOptions();
    if (params) {
      httpOptions = {
        ...this.httpOptions(),
        params: params
      };
    }
    return this.http.get(this.baseUrl + url, httpOptions).pipe(
      catchError((error) => {
        this.handleError(error); // Log and handle the error
        return throwError('An error occurred. Please try again later.');
      })
    );
  }

  // add manual polygon 
  postApi(apiPath:any, payload: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',             
        'x-api-key': this.x_api_key_manual_polygon
      })
    } 
    
    return this.http.post<any>( this.polygonManualIndexing + apiPath, payload, httpOptions)
      .pipe(
        catchError((error) => {
          this.handleError(error); // Log and handle the error
          return throwError('An error occurred. Please try again later.');
        })
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error.message?.toLowerCase() == 'email id not exists') {
      this.alertMessage('danger', 'Please contact your Administrator');
    } else if(error.error.message?.toLowerCase() == 'permission denied') {
      this.alertMessage('danger', 'Permission denied');
    } else if(error.error.message?.toLowerCase() == 'token expired') {
        this.clearStorage();
        this.authentication.currentUserSubject.next(null);
        window.location.reload();
            }
      this.loadSpinner(false);
    return throwError(() => {
      if (error.error.message?.toLowerCase() == 'token expired') {
        this.clearStorage();
        this.authentication.currentUserSubject.next(null);
        // reload the page
        window.location.reload()
      }
    });
  }

  clearStorage() {
    localStorage.removeItem('apartment_user')
   // localStorage.removeItem('apartmentPayload');
  }
}