<header class="position-relative">
    <div class="apt-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="logo-menu d-flex align-items-center ">
                        <a href="javascript:;" class="logo">
                            <img [src]="logo" routerLink="/dashboard" alt="logo" height="26">
                        </a>
                        <div class="apt-searchbar">
                            <app-search-bar *ngIf="showSearchBar"
                                (searchInputData)="getSearchedData($event)"></app-search-bar>
                        </div>

                        <div class="menu">
                            <nav class="navbar navbar-expand-sm p-0">
                                <a class="navbar-brand d-none" href="javascript:;">
                                    Menu
                                </a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbar-list-2" aria-controls="navbarNav" aria-expanded="false"
                                    aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbar-list-2">
                                    <ul class="navbar-nav ml-auto">

                                        <li class="nav-item  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                            <a class="nav-link" href="javascript:;" routerLink="/dashboard"><i class="fe fe-pie-chart mr-2"></i> Dashboard </a>
                                        </li>
                                        <li class="nav-item  " routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
                                            <a class="nav-link" href="javascript:;" [routerLink]="['/search']"><i class="fe fe-home mr-2"></i> Complex Search
                                            </a>
                                        </li>
                                        <li class="nav-item dropdown " [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                                            <a class="nav-link dropdown-toggle " href="#"  role="button" data-toggle="dropdown" aria-expanded="false"
                                                ><i class="fe fe-file mr-2"></i>
                                                Report
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" [routerLink]="['report/verification-stats']" [routerLinkActiveOptions]="{exact: true}"
                                                    routerLinkActive="active">Verification stats</a>
                                                <a class="dropdown-item " [routerLink]="['report']" [routerLinkActiveOptions]="{exact: true}"
                                                    routerLinkActive="active"> Detailed Report</a>
                                                <a class="dropdown-item " *ngIf="currentUser.role_id == 1" [routerLink]="['report/processed-stats']" [routerLinkActiveOptions]="{exact: true}"
                                                    routerLinkActive="active"> Processed stats</a>
                                            </div>
                                        </li>
                                        
                                        <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                                            <a class="nav-link" href="javascript:;" [appShowElement]="'task_list'" [routerLink]="'/task'"><i
                                                    class="fe fe-check-circle mr-2"></i>Task
                                                Management</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        <div class="user-info  d-flex align-items-center">
                            <div class="dropdown username-log">
                                <a href="javascript:;" class=" dropdown-toggle" data-toggle="dropdown">
                                    <div class="username">
                                        <!-- <img [src]="currentUser.profile_image" alt="" class="img-fluid"> -->
                                        <img [src]="userImage" class="img-fluid" referrerpolicy="no-referrer">
                                    </div>
                                    <div>
                                        <span class="d-block apt-uname">{{ currentUser?.first_name }}</span>
                                        <!-- <span class="d-block user-pos">Admin</span> -->
                                    </div>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a class="dropdown-item" href="javascript:;" [appShowElement]="'users_list'" *ngIf="currentUser.role_id != 3"
                                        [routerLink]="['/users']"><i class="fe fe-users mr-2"></i> Users</a>
                                    <a class="dropdown-item" href="javascript:;"
                                        [routerLink]="['/tags']"><i class="fe fe-tag mr-2"></i> Tags</a>
                                    <a class="dropdown-item" href="javascript:;"
                                        [appShowElement]="'role_list, permission_list'" [routerLink]="'/roles'"><i
                                            class="fe fe-user-check mr-2"></i> Roles
                                        & Permission</a>
                                        
                                    <!--   <a class="dropdown-item"  [appShowElement]="'task_list'"  [routerLink]="'/task'"><i class="fe fe-check-circle mr-2"></i>Task
                                            Management</a> -->
                                    <a class="dropdown-item" href="javascript:;" (click)="logout()"><i
                                            class="fe fe-power mr-2"></i> Log Out</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="apt-fliterh" *ngIf="showSearchBar && hasData" [ngClass]="showFilterDetails == true ? 'apt-fvfliter': ''">
        <div class="apt-filter " >
            <!-- <div class="f-overlay"></div> -->
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-12 ">
                            <a href="javascript:;" class="reset-f" *ngIf="showFilterDetails" (click)="filterDetails()"><i
                                class="fe fe-x-circle"></i></a>
                                <span class="filter-badge mr-3 pr-3"><i class="ti ti-filter mr-1"></i> <span class="line">Filter </span></span>
                        <ul>
    
                            <li class=" apt-lism" *ngIf="stateName">
                                <div class="f-title"><i class="ti ti-map-2 mr-1"></i>State </div>
                                <div class="f-valm"> <span class="f-val" [title]="stateName">{{stateName}}</span>
                                </div>
                            </li>
    
                            <li *ngIf="countyList && countyList.length > 0">
                                <div class="f-title"><i class="ti ti-map mr-1"></i>County
                                    <span>({{countyList.length}})</span>
                                </div>
                                <span>
                                    <div class="f-valm"> <span *ngFor="let item of countyList" class="f-val"
                                            [title]="item.county_label">
                                            {{item.county_label}}</span> </div>
                                </span>
                            </li>
    
                            <li *ngIf="cityList && cityList.length > 0">
                                <div class="f-title"><i class="ti ti-map-pin mr-1"></i>City
                                    <span>({{cityList.length}})</span>
                                </div>
                               
                                    <div class="f-valm"> <span *ngFor="let item of cityList" class="f-val"
                                            [title]="item.city_label">
                                            {{item.city}}</span> </div>
                               
                            </li>
    
                            <li class="f-wauto  apt-lism" *ngIf="zipList && zipList.length > 0">
                                <div class="f-title"><i class="ti ti-map-pin mr-1"></i>Zip <span>({{zipList.length}})</span>
                                </div>
                                <span>
                                    <div class="f-valm"> <span *ngFor="let item of zipList" class="f-val"
                                            [title]="item.zip">
                                            {{item.zip}}</span> </div>
                                </span>
                            </li>
    
                            <li *ngIf="msoList && msoList.length > 0">
                                <div class="f-title"><i class="ti ti-building-broadcast-tower mr-1"></i>MSO
                                    <span>({{msoList.length}})</span>
                                </div>
                                <span>
                                    <div class="f-valm"> <span class="f-val" *ngFor="let item of msoList"
                                            [title]="item">
                                            {{item}}</span> </div>
                                </span>
                            </li>
    
                            <li *ngIf="tags && tags.length > 0">
                                <div class="f-title"><i class="ti ti-building-broadcast-tower mr-1"></i>Tags
                                    <span>({{tags.length}})</span>
                                </div>
                                <span>
                                    <div class="f-valm"> <span class="f-val" *ngFor="let item of tags"
                                            [title]="item">
                                            {{item.tag_name}}</span> </div>
                                </span>
                            </li>
                            <li class="apt-lism" *ngIf="buildingCount">
                                <div class="f-title"><i class="ti ti-building-skyscraper mr-1"></i>Building Count</div>
                                <div class="f-valm"> <span class="f-val" [title]="buildingCount.title">
                                        {{buildingCount.title}}</span></div>
                            </li>
                            
                            <li class="apt-lism" *ngIf="unitCount">
                                <div class="f-title"><i class="ti ti-home mr-1"></i>Complex unit range</div>
                                <div class="f-valm"> <span class="f-val"
                                        [title]="unitCount.title">{{unitCount.title}}</span></div>
                            </li>
                            <!-- <li>
                                <button class="f-morereslt" *ngIf="!showFilterDetails" (click)="filterDetails()"> View All
                                    Filter Results <i class="ti ti-arrow-right ml-1"></i></button>
                            </li> -->
    
                        </ul>
                    </div>
    
    
                </div>
            </div>
        </div>
    </div>

</header>