import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../_core/services';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  providers: [MessageService]
})
export class AlertComponent implements OnInit {

  dismissible = true;
  alerts: any[] = []
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.apiService.alert.subscribe((val: any) => {
      if ("msg" in val) {
        if (val.type == "success") {
          this.messageService.add({ severity: 'success', detail: val.msg });
        } else if (val.type == "warn") {
          this.messageService.add({ severity: 'warn', detail: val.msg });
        } else if (val.type == "info") {
          this.messageService.add({ severity: 'info', detail: val.msg });
        } else if (val.type == "error" || val.type == "danger") {
          this.messageService.add({ severity: 'error', detail: val.msg });
        } else if (val.type == "clear") {
          this.messageService.clear();
        }
      }
    })
  }

  onClosed(dismissedAlert: any): void {
    // this.alerts = this.alerts.filter(alert => alert !== dismissedAlert);
  }
  clear() {
    this.messageService.clear();
  }
}
