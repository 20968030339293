export interface Task {
    first_name:string,
    last_name:string,
    email:string,
    role_id:number,
    created_by:number,
 }
 export interface TaskCreate {
    title : string,
    description : string,
    task_status : number,
    start_date : string,
    end_date : string,
    assignee : number,
    report_to : number,
    state : [string],
    city : [string],
    zip : [string]
}

const TaskCreateDefaultVal : TaskCreate = {
    title : '',
    description : '',
    task_status : 1,
    start_date : '',
    end_date : '',
    assignee : 0,
    report_to : 0,
    state : [''],
    city : [''],
    zip : ['']
}

export class TaskCreateValue {
    constructor(initialize: TaskCreateValue) {
        let value = {...TaskCreateDefaultVal, ...initialize}
        return value
    }
}

// Modal Configurations
export const modalConfig = {
    animated: true,
    backdrop : 'static',
    keyboard : false
}