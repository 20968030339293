import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { API_CONFIG, mapSettings } from 'src/app/_config';
import { ApartmentService } from 'src/app/apartment/apartment.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  constructor(private apartmentService: ApartmentService, private apiService: ApiService) { }

  public stateList = new BehaviorSubject(null)
  stateList$ = this.stateList.asObservable();

  getZoomLevelbySearch(payload: any) {

    const hasState = payload.filters.hasOwnProperty('state') && Array.isArray(payload.filters.state) && payload.filters.state.length > 0;
    const hasCity = payload.filters.hasOwnProperty('city') && Array.isArray(payload.filters.city) && payload.filters.city.length > 0;
    const hasZip = payload.filters.hasOwnProperty('zip') && Array.isArray(payload.filters.zip) && payload.filters.zip.length > 0;
    if (hasZip) {
      this.apartmentService.changezoomLevel({ zoom: mapSettings.zipLevelZoom, value: payload.filters.zip, type: 'zip' })
    }
    else if (!hasZip && hasCity) {
      this.apartmentService.changezoomLevel({ zoom: mapSettings.citylevelZoom, value: payload.filters.city, type: 'city' })
    }
    else if (!hasZip && !hasCity && hasState) {
      this.apartmentService.changezoomLevel({ zoom: mapSettings.statelevelZoom, value: payload.filters.state, type: 'state' })
    }
    else {
      this.apartmentService.changezoomLevel({ zoom: mapSettings.defaultZoom, value: payload.filters.complex_name, type: 'text' })
    }

  }

  // To get Geography information
  getGeography(searchTerm?: any, url?: any, payload?: any): Observable<any> {
    if (searchTerm !== '') {
      payload['searchTerm'] = searchTerm;
    }
    return this.apiService.post(url, payload).pipe(
      map((response: any) => {
        if ('total' in response) {
          return response;
        }
      })
    );
  }
}
