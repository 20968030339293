import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiService, AuthService } from './_shared';
import { Subscription, filter, take } from 'rxjs';
import * as moment from "moment";
import { ApartmentService } from './apartment/apartment.service';
export let browserRefresh = false;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loader = false;
  currentUser: any
  user: any;
  showSearch = false;
  subscription: Subscription;
  
  constructor(
    public router: Router,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private authentication: AuthService,
    private apartmentService: ApartmentService
  ) {
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
        if(event.url.includes('building')) {
          this.apartmentService.buildingList$.pipe(take(2)).subscribe((res: any) => {
          if(res.length > 0)
          this.apartmentService.buildings.next(res);
        });   
        } else {
           if(event.url.includes('search')) {
             if(JSON.parse(localStorage.getItem('apartmentPayload')!))
             this.apartmentService.search(JSON.parse(localStorage.getItem('apartmentPayload')!)).subscribe((vl: any) => {
             });
           }
        }
      }
    });

      this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          // if(event.url.includes('building'))
          // this.router.navigate(['/search/list']);
        }
      });
      
    this.authentication.currentUserSubject.subscribe((data: any) => {
      this.currentUser = null
      if (data) {
        this.currentUser = (data);

        if (!Object.prototype.hasOwnProperty.call(this.currentUser, 'email')) {
          this.router.navigateByUrl('/');
        } else if ('exp' in data) { // Check API token expiration and redirect to login page

          if (moment().isAfter(Number(data.exp + '000'), 'minute')) {
            this.apiService.clearStorage()
            this.authentication.currentUserSubject.next(null)
            this.router.navigateByUrl('/');
          }
        }
      }
    })
  }

  showSearchedData(event: any) {
    this.showSearch = event;
  }

  ngOnInit() {
    this.user = this.authentication.currentUserValue;
    this.apiService.loader.subscribe((res: any) => {
      if (this.loader != res) {
        this.loader = res;
        this.cdr.detectChanges();
      }
    });
  }
}