import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService, AuthService } from '../../../_core/services';
import { API_CONFIG, imagePath, initialPayload } from 'src/app/_config';
import { filter } from 'rxjs';
import { GeoService } from 'src/app/_core/services/geo.service';
import { ApartmentService } from 'src/app/apartment/apartment.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Output() user = new EventEmitter<any>();
  showHeader = false;
  currentUser: any;
  userImage: any;
  showSearchBar = true;
  logo = imagePath.login_logo;
  searchedData: any;
  state: any;
  stateList: any;
  stateName: any;
  countyList: any;
  cityList: any;
  zipList: any;
  msoList: any;
  buildingCount: any;
  unitCount: any;
  showFilterDetails = false;
  @Output() showSearchedData = new EventEmitter<any>();
  hasData = true;
  tags: any = [];
  tagsList: any = [];

  constructor(
    public router: Router,
    private apiService: ApiService,
    private authService: AuthService,
    private geoService: GeoService,
    private apartmentService: ApartmentService,

  ) {
    this.router.events.subscribe(value => {
      this.showHeader = this.router.url.toString().includes('dashboard')
    });
    this.apartmentService.tags$.subscribe((res) => {
      this.tagsList = res;
    });

    this.authService.currentUserSubject.subscribe((data: any) => {
      this.currentUser = null
      if (data) {
        this.userImage = localStorage.getItem('apartment_user_image');
        this.currentUser = (data);
        if (!Object.prototype.hasOwnProperty.call(this.currentUser, 'email')) {
          this.router.navigateByUrl('/');
        }
      }
    })
  }

  logout() {
    this.apiService.clearStorage();
    this.authService.currentUserSubject.next(null);
    this.authService.currentUserSubject.subscribe((data: any) => {
      this.user.emit(data)
    });
    this.router.navigate(['/login']);
    this.apiService.post(API_CONFIG.userLogout, '').subscribe({
      next: (res: any) => {
        if (res != null && res != undefined) {
          // this.apiService.alertMessage("success", res.message);
        }
      },
      error: (err: any) => {
        console.error(err);
        this.apiService.alertMessage("danger", " Please try again later.");
      }
    });
  }

  getSearchedData(event: any) {
    this.hasData = this.hasValue(event);
    this.showSearchedData.emit(this.hasData);
    this.searchedData = event;
    this.getState(this.searchedData.state);
    this.countyList = this.hasObject(this.searchedData.county) ? this.searchedData.county : this.convertToObjects(this.searchedData.county, 'county', 'county_label');
    this.tags = this.getTags(event.tags) 
     
    this.cityList = this.hasObject(this.searchedData.city) ? this.searchedData.city : this.convertToObjects(this.searchedData.city, 'city', 'city_label');
    this.zipList = this.hasObject(this.searchedData.zip) ? this.searchedData.zip : this.convertToObjects(this.searchedData.zip, 'zip', 'zip_label');

    this.msoList = this.searchedData.mso;
    this.buildingCount = this.searchedData.building_count ? this.searchedData.building_count :this.searchedData?.building_range;
    this.unitCount = this.searchedData.unit_range;
  }

  hasObject(array: any[]): boolean {
    return array?.some(item => typeof item === 'object') ?? false;
  }

  filterDetails() {
    this.showFilterDetails = !this.showFilterDetails
  }

  getState(input: string) {

    if (input?.length > 0) {
      this.geoService.stateList$.subscribe((res: any) => {
        if (res) {
          this.stateList = res;
          const filteredStates = this.stateList.filter((state: any) => state.state.includes(input));
          this.stateName = filteredStates && filteredStates.length > 0 ? filteredStates[0].state_full_name : ''
        }
      })
    } else {
      this.stateName = null;
    }
  }

  hasValue(obj: any) {
    const fieldsToCheck = ['zip', 'city', 'county', 'mso', 'state', 'unit_range', 'building_range', 'tags'];
  
    for (const key of fieldsToCheck) {
      const value = obj[key];
  
      if (value !== null) {
        if (Array.isArray(value) && value.length > 0) {
          return true;
        } else if (typeof value === 'object' && 'title' in value && typeof value.title === 'string' && value.title.trim() !== '') {
          return true;
        } else if (typeof value === 'string') {
          return true;
        } else if (typeof value === 'number') {
          return true;
        }
      }
    }
  
    return false;
  }
  

  convertToObjects(cities: string[], name: any, label: any) {
    return cities?.map(city => {
      if(city?.includes('_')) {
        const [state, label] = city.split('_');
        const result: any = {};
        result[name] = label.toUpperCase();
        result[label] = label.toUpperCase();
        return result
      } else {
        const result: any = {};
      result[name] = city.toUpperCase();
      result[label] = city.toUpperCase();
      return result;
      }
    });
  }

  getTags(tags: any) {
    if(tags && tags.length > 0 &&  typeof tags[0] == 'object') return tags
    return this.tagsList && this.tagsList.filter((tag: any) => tags?.includes(tag.id));
  }

  ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (event.url) {
          this.showSearchBar = event.url.includes('search') || event.urlAfterRedirects.includes('search');
        }
      });

    setTimeout(() => {
      const ip = JSON.parse(localStorage.getItem('apartmentPayload')!) ? JSON.parse(localStorage.getItem('apartmentPayload')!).filters : initialPayload
      this.getSearchedData(ip);
    }, 500);
  }
}
