
<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="apt-table card d-flex align-items-center justify-content-center">
                   
                    <img [src]="accessDeniedImage" class="img-fluid" width="600"
                    >
                    <h4>Access Denied/Forbidden </h4>
                    <p>You dont have permission to view this page.</p>
                    <button  routerLink="/search" class="btn btn-default" >Back To Home</button>
                </div>

            </div>
        </div>
    </div>
</section>
