<div class="apt-loader-c">
    <div class="triangles">
        <div class="tri invert"></div>
        <div class="tri invert"></div>
        <div class="tri"></div>
        <div class="tri invert"></div>
        <div class="tri invert"></div>
        <div class="tri"></div>
        <div class="tri invert"></div>
        <div class="tri"></div>
        <div class="tri invert"></div>
    </div>
</div>