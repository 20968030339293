<section class="login-wrapper">
    <div class="elipse1"><img [src]="ellipse" class="img-fluid"></div>
    <div class="elipse2"><img [src]="ellipse" class="img-fluid"></div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 p-0">
                <div class="sci-aplogin">
                    <div class="logo">
                        <img [src]="logo" class="img-fluid" height="41" />
                    </div>
                    <div class="p-5">

                    
                    <div class="intro">
                        <h3>Welcome to <span>Apartment</span></h3>
                    </div>
                                      
                    <div class="w-50">
                        <button hidden id="hidden-btn" ></button>
                        <button type="submit" id="google-btn" class="btn btn-dark btn-block ">
                            Sign In
                        </button>
                    </div>
                   
                </div>
                </div>
            </div>
            <div class="col-lg-8 sci-apdes p-0">
                <div class="sci-apdesinner">
                    <!-- <h3><span class="d-block">Experience the Next Generation of Service</span> -->
                         <!-- Where Innovation Meets Excellencee<span>.</span></h3> -->
                        <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing <br>
                            elit, sed diam nonummy nibh euismod.</p> -->

                </div>
            </div>
        </div>
    </div>
</section>