export const defaultStatesPayload = {
    "search": "",
    "page": 1,
    "size": 62,
    "search_type": "starts_with",
    "sort_order": "asc",
    "sort_field": "state",
}

export const defaultCitiesPayload: any = {
    "search": "",
    "search_type": "starts_with",
    "state": [],
    "page": 1,
    "size": 100,
    "sort_order": "asc",
    "sort_field": [
        "state",
        "city"
    ]
}

export const defaultZipPayload: any = {
    "search": "",
    "search_type": "starts_with",
    "state": [],
    "city": [],
    "page": 1,
    "size": 100,
    "sort_order": "asc",
    "sort_field": [
        "state",
        "city",
        "zip"
    ]
}

export const TaskValues = {
    firstPage : 1,
    pageSize: 15,
    first: 0,
    listAllUserToAdmin : true
}

// Task Get Payload
export const defaultTaskListPayload = {
    "filters" :{
        "search" : "",
        "assignee_to" : []
    },
    "sort_field":"created_on",
    "sort_order":"desc",
    "page":TaskValues.firstPage,
    "size": TaskValues.pageSize
}

export const defaultTaskStatusPayload = {
    "sort_field" : "created_on",
    "sort_order" : "asc",
    "page" : TaskValues.firstPage,
    "size" : 20
}

export const defaultReportPayload = {
    "filters": {
      "state": [ ],
      "city": [ ],
      "zip": [ ],
      "county": [ ],
      "user": [ ],
      "date_range": {
        "date_from": new Date(),
        "date_to": new Date()
      },
      "change_info": "",
      "search_value": "",
      "search_type": "contains",
      "approved_status": [
        "pending",
        "approved",
        "rejected"
      ]
    },
    "sorting": {
      "sort_field": "verified_on",
      "sort_order": "asc"
    },
    "page": 1,
    "size": 20
  }

export const defaultProcessedPayload = {
    "filters": {
      "state": [ ],
      "city": [ ],
      "zip": [ ],
      "county": [ ],
      "date_range": {
        "date_from": new Date(),
        "date_to": new Date()
      }
    },
    "sorting": {
      "sort_field": "processed_on",
      "sort_order": "asc"
    },
    "page": 1,
    "size": 20
}  