export const defaultSearchPayload = {
    "search": "",
    "search_type": "starts_with",
    "page": 1,
    "size": 20,
    "sort_order": "asc",
    "sort_field": [
        "first_name",
        "last_name",
        "email",
        "role_id"
    ]
}

export const defaultRolesPayload = {
    "search_type": "starts_with",
    // "page": 1,
    // "size": 20,
    "sort_order": "asc",
    "sort_field": "name"
}

export const userListPaginationValue ={
    firstPage: 1,
    pageSize: 100
}
