export const ApartmentModule = {
    itemsPerPage: 100,
    pageNumber: 1,
    recordsCount: 100,
    showPagenumberCount: 4,
    zip: '60639',
    state: 'IL',
    city: ['WESTMONT (Illinois)'],
    unitRangeMin: 0,
    updateBuildingTagName: true,
    unitRangeMax: 10000,
    sortOrder: 'asc',
    sortField: 'address',
    searchType: 'starts_with',
    // mappedStatus: 'mapped',
    verification_status : ['unverified','verified'],
    approved_status: ['new','pending'],
    buildingCountRangeMin: 0,
    buildingCountRangeMax: 1000,
    tableCount: 15,
    loadInitialCity: [
        {
            "state": "IL",
            "city": "WESTMONT",
            "city_label": "WESTMONT (Illinois)",
            "city_control": "IL_WESTMONT",
            "state_full_name": "Illinois"
        }
    ]
}

export const markerChangeRequest = {
  latLon : false,
  unmap: false,
  addlayer: false,
  address: false,
  close: false,
  others: false
}

export const unitRangeList = [
    { title: 'Complexes 200+ Units', from: 200},
    { title: 'Complexes between 100-200 units', from: 100, to: 200 },
    { title: 'Complexes between 50-100 units', from: 50, to: 100 },
    { title: 'Complexes 50+ Units', from: 50},
    { title: 'Complexes between 10-50 Units', from: 10, to: 50},
    { title: 'Complexes less than 10 Units', from: 0, to: 9},
]

export const providersImage = {
    comcast: '../../assets/img/providers/comcast.png' ,
    charter: '../../assets/img/providers/charter.png' ,
    brighthouse: '../../assets/img/providers/brighthouse.png' ,
    cox: '../../assets/img/providers/cox.png' ,
    mediacom: '../../assets/img/providers/mediacom.png' ,
    timewarner: '../../assets/img/providers/timewarnercable.png' 
}

export const Users = {
    countPerPage: 100,
    TableListPerPage: 10
}

// Allow multiple approvals for 10 requests per request count and set a limit of 30 multi select
export const MultipleRecordsCount= {   
     RecordsPerApprovalRequest: 5,         
     MultiselectMaximumCount: 30,
    // SelectallMultiplecount:29            
}

export const DefaultStatusCode = {
    0: 'Inactive',
    1: 'Active'
}

export const initialPayload = {
  "state": ["IL"],
}

export const RecordsPerPage = {
    PermissionsPerPage: 10
}

export const imagePath = {
    login_ellipse : '../../assets/img/ellipse.png',
    login_logo : '../../../assets/img/logo.svg',
    access_denied : '../../../../assets/img/403-forbidden.png',
    no_data_found: '../../../assets/img/no-data.png',
    empty_box: 'assets/img/empty-box.png'
}

// Building Count Range
export const BuildingCountRange = [
    { title: '200+ Buildings', from: 200},
    { title: '100 to 200 Buildings', from : 100, to: 200},
    { title: '50 to 100 Buildings', from : 50, to: 100},
    { title: '10 to 50 Buildings', from: 10, to: 50},
    { title: '10+ Buildings', from: 10},
    { title: 'Less than 10 Buildings', from: 0, to: 9},
]

// Temp Login Mail Id's
// export const  TEMPORARY_PAUSE = [
//     'kannank@sciera.com',
//     'Rajarajan@sciera.com',
//     'saravanans@sciera.com'
//     // 'gowthams@sciera.com',
//     // 'divyadevil@sciera.com',
//     // 'sivar@sciera.com',
//     // 'karthik@sciera.com',
//     // 'satishr@sciera.com',
//     // 'sathishkumarp@sciera.com',
//     // 'padmaneshnc@sciera.com',
//     // 'sharmilap@sciera.com',
//     // 'karthikak@sciera.com',
//     // 'fredricka@sciera.com'
// ]
