import { AbstractControl } from '@angular/forms';

//Input empty space validators
export function spaceValidator(control: AbstractControl) {
    let inputReq = control?.errors?.['required'] === true ? true : false;
    if (control.value && control.value.trim() === '') {
        control.setValue('');
        return inputReq ? { required: inputReq } : null;
    } else if ((control.value && /\s /.test(control.value))) {
        control.setValue(control.value.trim());
        return inputReq ? { required: inputReq } : null;
    }
    return null;
}