import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DivLoaderComponent } from './components/div-loader/div-loader.component';
import { StatusNamePipe } from './pipes/status-name.pipe';
import { UtcToLocalPipe } from './pipes/utc-to-local.pipe';



@NgModule({
  declarations: [
    DivLoaderComponent,
    StatusNamePipe,
    UtcToLocalPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DivLoaderComponent,
    StatusNamePipe,
    UtcToLocalPipe
  ]
})
export class CommonSharedModule { }
