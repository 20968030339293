import { Injectable } from '@angular/core';
import { userList } from 'src/app/_core/models/user';
import { ApiService } from '../_core/services';
import { API_CONFIG } from '../_config/APIPath';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { userListPaginationValue } from './users.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private users = new BehaviorSubject<any>(null)
  public userLists = this.users.asObservable()
  searchUsers: string = '';

  constructor(
    private apiService: ApiService
  ) {
  }

  //Get Users List
  getUsersList(val: any): Observable<any> | any {
    try {
      this.apiService.loadSpinner(true);
      let payload: userList = {
        search: val.searchUsers,
        search_type: "starts_with",
        sort_order: "desc",
        sort_field: [
          "created_on",
        ]
      }
      return this.apiService.post(API_CONFIG.users, payload).pipe(
        map((val) => {
          

          this.apiService.loadSpinner(false);
          if ('users' in val) {
            let usr  = val.users.map((values:any) => {values.fullname = values.first_name+' '+values.last_name; return values})

            this.users.next(usr)
            return val
          } else
            return null
        }))

    } catch (e) {
      this.apiService.loadSpinner(false);
      return null
    }
  }
}