import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '../../_core/services';
import jwt_decode from "jwt-decode";

@Directive({
  selector: '[appShowElement]'
})
export class ShowElementDirective implements OnInit {
  @Input() appShowElement: any;
  permissionList: any;
  role: any;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private authentication: AuthService
  ) {
    this.authentication.currentUser.subscribe((user: any) => {
      if (user) {
        this.permissionList = user.permissions;
        this.role = user.role_id;
      } else {
        const user = localStorage.getItem('apartment_user');
        if (user) {
          const userDetails: any = jwt_decode(user);
          this.permissionList = userDetails.permissions;
          this.role = userDetails.role_id;
          this.authentication.currentUserSubject.next(jwt_decode(user))
        }
      }
    });
    // console.log(this.permissionList);
  }


  ngOnInit(): void {
    const containsComma = this.appShowElement.includes(',');
    if (containsComma) {
      const arr = this.appShowElement.split(', ');
      let storeData =[];
      for (const item of arr) {
        const element = this.permissionList.includes(item);
        if (element) {
          storeData.push(item)
        }
      }
      if(storeData.length == 0){
        this.removeElement();
      }
    } else {

      if (this.role !== 1 && !this.permissionList.includes(this.appShowElement)) {
        this.removeElement();
      }
    }

  }

  // Method to remove the element
  removeElement() {
    const element = this.elementRef.nativeElement;
    const parent = this.renderer.parentNode(element);
    this.renderer.removeChild(parent, element);
  }
}
