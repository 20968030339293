export enum API_CONFIG {

    // users
    userLogin                               = 'users/login',
    userLogout                              = 'users/logout',
    users                                   = 'users',
    createUser                              = 'users/create',
    checkMailId                             = 'users/check/mailid',
    
    // search
    apartmentSearch                         = 'complexes/search',
    apartmentAdvancedSearch                 = 'complexes/advance-search',
    nameSearch                              = 'complexes/names',
    addressSearch                           = 'complexes/address-search',
    
    // geo search
    getStates                               = 'geo/states',
    getCities                               = 'geo/cities',
    getDmas                                 = 'geo/dmas',
    getZips                                 = 'geo/zips',
    getCounty                               = 'geo/counties',
    getMsoList                              = 'geo/mso',

    // Roles
    roles                                   = 'roles',
    createRole                              = 'roles/create',
    
    // Permissions
    permissions                             = 'permissions',
    
    // Task management
    getTaskList                             = 'task-details/list',
    createTask                              = 'task-details',
    taskStatus                              = 'task-statuses',

    // complex 
    complexes                               = 'complexes',
    complexHistory                          = 'complexes/history',      
    complexCreate                           = 'complexes/create',
    complexNameSearch                       = 'complexes/check/complex_name',
    complexTagUpdate                        = 'complexes/tag-update/',

    
    // Buildings
    building                                = 'buildings',
    buildingCreate                          = 'buildings/create',
    mapped                                  = 'mapped',
    approve                                 = 'approve',
    list                                    = 'list',
    buildingHistory                         = 'buildings/history',
    buildingSearchList                      = 'buildings/search/list',
    buildingTagUpdate                       = 'buildings/tag-update/',

    polygonSearch                           = 'il_buildings/_search',
    addPolygonName                          = 'il_buildings/_update_by_query',

    // Tags 
    tagList                                 = 'tags/list',
    tagCreate                               = 'tags/create',
    tagUpdate                               = 'tags/update',

    // Reports 
    complexReport                           = 'reports/complex-widgets',
    buildingReport                          = 'reports/building-widgets',
    recentComplexReport                     = 'reports/complex-recent-list',
    recentBuildingReport                    = 'reports/building-recent-list',
    complexUniqueReport                     = 'reports/complex-verification-stats',
    buildingUniqueReport                    = 'reports/building-verification-stats',
    complexUniqueDetailReport               = 'reports/complex-unique-verified-list',
    buildingUniqueDetailReport              = 'reports/building-unique-verified-list',
    complexSnowFlakeStats                   = 'reports/snowflake-complex-verified-stats',
    buildingSnowFlakeStats                  = 'reports/snowflake-building-verified-stats',
    complexSnowFlakeList                    = 'reports/complex-snowflake-verified-list',
    buildingSnowFlakeList                   = 'reports/building-snowflake-verified-list',

    // Manual polygon indexing 
    polygonTarget                          = 'proximity',
}