import { environment } from "../../environments/environment";

// Login provider details
export const allowedGmailDomain = ['sciera.com'];
export const loginProvider = 'Google';
// export const googleClientId = ['817890910358-8hug3f3f7o7gugm0cj4jp3d0d74l4a9a.apps.googleusercontent.com'];
export const googleClientId = environment.googleClientId

// API Endpoint 
// export const baseUrl = 'https://3572ux477f.execute-api.us-east-1.amazonaws.com/api/';
export const baseUrl = environment.baseUrl;

// QuickSight dashboard 
// export const x_api_key_quicksight = 'VdfYtH7pI42Bf9EjNulGz4NQmahPdDr9koH8icg0';
// export const quicksightUrl = 'https://6btbwawi8l.execute-api.us-east-1.amazonaws.com/v1/qs-embed';
export const x_api_key_quicksight = environment.x_api_key_quicksight;
export const quicksightUrl = environment.quicksightUrl;
// export const dashboardId = environment.dashboardId; //"f65782d5-284d-49fb-9698-ebc228390491" //"1bf906ab-d74a-4e0f-a551-bbe307be39ab"//"f65782d5-284d-49fb-9698-ebc228390491";
                            
// polygon draw on map 
export const x_api_key_polygon = environment.x_api_key_polygon;
export const polygon_endpoint = environment.polygon_endpoint;

// Maunal Polygon Indexing
export const polygonManualIndexing = environment.polygonManualIndexing
export const x_api_key_manual_polygon = environment.x_api_key_manual_polygon

// google Map details 
export const googleMap = {

    production: false,
    version: '1.0.0',
    // api: 'https://0gm7cs2qqe.execute-api.us-east-1.amazonaws.com/dev/apartments/api/',
    api: environment.googleMapApi,
    defaultLanguage: 'en-US',
    supportedLanguages: ['en-US', 'fr-FR'],
 
    // api_key: '0PK7KeCuI15Y2mcV3mw8Ua1NVE1gP7bvahiHNlwV',
    api_key: environment.googleMap_api_key,
    // google_map_key: 'AIzaSyBMPfb1OQsBP8yp18uSJKR17u2b5PMTxU4',
    google_map_key: environment.google_map_key,


};

export const googleSearchBaseUrl = 'https://www.google.com/search';
//https://us-east-1.quicksight.aws.amazon.com/sn/accounts/591885183981/dashboards/6b689e0d-866c-4b9e-bad9-5cbb7f521951?directory_alias=usclientservices