export const environment : any = {


    production          : false,

    googleClientId      : null,

    baseUrl             : null,

    x_api_key_quicksight: null,

    quicksightUrl       : null,

    quiksightDashboardId: null,

    googleMapApi        : null,

    googleMap_api_key   : null,

    google_map_key      : null,

    mapId               : null,

    awsSecretCdn        : 'https://drcgxlydn7.execute-api.us-east-1.amazonaws.com/dev/api',
    awsSecretXapi       : 'i3EXuAQOoH4euQStGzIm63ggyuTRD3VR829EMEOr',

    DE_ENV              : 'U2FsdGVkX1/Wk2SzZ2oHGKFEbF/GcIBmHtWYBoXqosGoPTINaat6LMBa95frbCpe', // Development

    x_api_key_polygon   : null,

    polygon_endpoint    : null,

    polygonManualIndexing : null,

    x_api_key_manual_polygon: null,

    rejectTagIds : [9, 10, 12, 22, 23, 38, 39, 40, 41]

}