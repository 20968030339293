import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { modalConfig } from '../_core/models/task';
import { Table } from 'primeng/table';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService, AuthService } from '../_shared';
import { API_CONFIG, imagePath } from '../_config';
import { TagList } from '../apartment/apartment.model';
import { spaceValidator } from '../_shared/validators/spaceValidators';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  @ViewChild('userTemplate') userTemplate !: TemplateRef<any>
  @ViewChild('dt') dt: Table | any;
  modalRef?: BsModalRef;
  config: any = modalConfig;

  tagnameForm!: FormGroup;
  actionType: any;
  tagnameList: any = [];
  rows = 15;
  currentUser: any;
  currentTag: any;
  noDataImage = imagePath.no_data_found;
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private authService: AuthService
    ){
      this.authService.currentUser.subscribe((user) => {
        if(user != null && user != undefined) {
          this.currentUser = user;
        }
      })
    }

  // To Modal patch value while edit
  createTagname(type: any, tag?:any) {
    this.actionType = type;
    this.currentTag = tag
    this.modalRef = this.modalService.show(this.userTemplate, this.config);
    if(type == 'Edit') {
      this.tagnameForm.patchValue({
        tagName: tag.tag_name
      })
    }
  }

  // For the Tag Search
  applyFilterGlobal($event: any, stringVal: any) {
    // this.getTagList('isSearch');
    this.dt.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
  }

  // To update the tag status
  tagnameStatusUpdate(data: any, event:any) {
    let tagUpdate = {
      "tag_name" : data.tag_name,
      "is_active": event.target.checked == true ? 1 : 0 
    }
    this.apiService.loadSpinner(true);
    this.apiService.put(API_CONFIG.tagUpdate + '/' + data.id, tagUpdate).subscribe((res) => {
      this.apiService.loadSpinner(false);
      if(res && 'tags' in res) {
        this.apiService.alertMessage('success', 'Tag Updated Successfully!');
        this.getTagList();
      }
    }, error => {
      this.apiService.loadSpinner(false);
      this.apiService.alertMessage('danger', 'Failed to Update Tag, Please try again!');
    })
  }

  formInit() {
    this.tagnameForm = this.formBuilder.group({
      tagName: [null, [Validators.required, Validators.minLength(5), spaceValidator]],
    });
  }

  // To close the Modal
  closeModal() {
    this.modalRef?.hide();
    this.formInit();
  }

  // On Create/Edit
  onSubmit(type: any) {
    if(this.tagnameForm.valid) {
      this.apiService.loadSpinner(true);
      if(type == 'Create') {
        let tagCreate = {
          "tag_name" : this.tagnameForm.get('tagName')?.value,
          "created_by" : this.currentUser.id
        }

        this.apiService.post(API_CONFIG.tagCreate, tagCreate).subscribe((res) => {
          this.apiService.loadSpinner(false);
          if(res && 'tags' in res) {
            this.closeModal();
            this.getTagList(); // Call the tag list api after the creation of the tag
            this.apiService.alertMessage('success', 'Tag Created Successfully!');
          }
        }, error => {
          this.apiService.loadSpinner(false);
          if(error.error.error_code == 302) {
            this.tagnameForm.get('tagName')?.setErrors({tagExist: true});
          }else this.apiService.alertMessage('danger', 'Failed to Create Tag. Please try again!');
        })
      }else{
        let tagUpdate = {
          "tag_name" : this.tagnameForm.get('tagName')?.value,
          "is_active": this.currentTag.is_active  
        }

        this.apiService.put(API_CONFIG.tagUpdate +'/'+ this.currentTag.id, tagUpdate).subscribe((res) => {
          this.apiService.loadSpinner(false)
          if(res && 'tags' in res) {
            this.closeModal();
            this.getTagList();
            this.apiService.alertMessage('success', 'Tag Updated Successfully!');
          }
        }, error => {
          this.apiService.loadSpinner(false);
          this.apiService.alertMessage('danger', 'Failed to Update Tag. Please try again!');
        })
      }
    }
  }

  // To get the tag list
  getTagList(type?:any) {
    this.apiService.loadSpinner(true);
    let payload = TagList
    if(type == 'pagination') payload['page'] = payload['page'] +1;
    this.apiService.post(API_CONFIG.tagList+'?tag_param=total_list', payload).subscribe((res) => {
      this.apiService.loadSpinner(false)
      if(res && 'tags' in res) {
        this.tagnameList = res['tags'];
      }
      // console.log(res)
    }, error => {
      this.apiService.loadSpinner(false);
      this.apiService.alertMessage('danger', 'Failed to get Tags list, Please try again!')
    })
  }

  ngOnInit(): void {
    this.formInit();
    this.getTagList();
    // this.tagnameList = [{name: 'Apartment', is_active: true}]
  }
}
