import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';

export const authGuard: CanActivateFn = (route, state) => {

    const service = inject(AuthService);
    const router = inject(Router);

    let permissionList: any = [];
    service.currentUser.subscribe((res) => {
        if(res) {
            permissionList = res.permissions;
        }
    })
    let currentUrl = state.url;

    if (inject(AuthService).isLoggedIn()) {

        const typeToUrlMap: any = {
            '/roles': 'role_list',
            '/users': 'users_list',
            '/task': 'task_list',
        };  
        const url = typeToUrlMap[currentUrl];
        const secondCheck: any = {
            '/roles': 'permission_list',
        };
        const secondUrl = secondCheck[currentUrl];
        
        if ( url && !permissionList.includes(url) && secondUrl && !permissionList.includes(secondUrl)) {
            router.navigate(['/access-denied']);
            return false
        } else {
            return true
        }

    } else {
        router.navigate(['/login']);
        return false
    }

};
