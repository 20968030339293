<footer>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    © Copyright {{copyrightDate | date :"YYYY"}} Apartment | All rights reserved
                </div>
            </div>
        </div>
    </div>
</footer>