import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HeaderComponent, FooterComponent, AlertComponent } from './_shared';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { SearchBarComponent } from './_shared/components/search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DatePipe } from '@angular/common';
import { DirectivesModule } from './_shared/directives/directives.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TableModule } from 'primeng/table';
import { AccessDeniedComponent } from './_shared/components/access-denied/access-denied.component';
import { CommonSharedModule } from './_shared/common-shared.module';
import { ArrayToStringPipe } from './_shared/pipes/array-to-string.pipe';
import { ConfigService } from './_config/config.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TagsComponent } from './tags/tags.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ChartModule } from 'angular-highcharts';
import { ReportComponent } from './report/report.component';
import { PaginatorModule } from 'primeng/paginator';

export function initializeApp(configService: ConfigService) {
  return () => configService.getConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    AlertComponent,
    SearchBarComponent,
    DashboardComponent,
    AccessDeniedComponent,
    ArrayToStringPipe,
    TagsComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientJsonpModule,
    ToastModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    AccordionModule,
    BrowserAnimationsModule,
    CollapseModule,
    DirectivesModule,
    CommonSharedModule,
    TableModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    ChartModule,
    PaginatorModule
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ConfigService],
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
