export const mapSettings = {
    innerRadius: 0.4, // in km
    outerRadius: 500, // in meters
    polygonMaxCount: 2000,
    minZoom: 2,
    maxZoom: 18,
    statelevelZoom: 5,
    citylevelZoom: 10,
    zipLevelZoom: 15,
    defaultZoom: 10,
    nearbyComplexZoom: 14,
    polygonbuildingZoom: 11,
    enableSatelliteView: 16,
    existingSatellite: '#FFFF89',
    existingRoadmap: '#2D698C'
}

export const mapIcons = {
    complexVerified: '../../../assets/img/map-icons/apt-com-verified.svg',
    complexUnverified: '../../../assets/img/map-icons/apt-com-unverified.svg',
    complexRejected: '../../../assets/img/map-icons/apt-com-rejected.svg',
    complexPending: '../../../assets/img/map-icons/apt-com-pending.svg',
    buildingUnverified: '../../../assets/img/map-icons/apt-bul-unverified.svg',
    buildingVerified: '../../../assets/img/map-icons/apt-bul-verified.svg',
    buildingRejected: '../../../assets/img/map-icons/apt-bul-rejected.svg',
    buildingPending: '../../../assets/img/map-icons/apt-bul-pending.svg',
    newMarker: '../../../assets/img/map-icons/apt-new.svg',
    addressPin: '../../../assets/img/map-icons/apt-mpin.svg',
    nearbyComplex: '../../../assets/img/map-icons/nearby-pin.svg',
    tagNameComplex: '../../../assets/img/map-icons/apt-tagname.svg',
    googleIcon: '../../../assets/img/map-icons/google-maps.png'
}

export const checkBoxOptions = [
    { title: 'Unverified', value: 'new' },
    { title: 'Pending', value: 'pending' },
    { title: 'Verified', value: 'approved' },
    { title: 'Rejected', value: 'rejected' }
]

export const mapConfig = [
    {
        "featureType": "administrative.land_parcel",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    /*  {
       "featureType": "road.arterial",
       "elementType": "labels",
       "stylers": [
         {
           "visibility": "off"
         }
       ]
     }, */
    {
        "featureType": "road.highway",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    /*  {
       "featureType": "road.local",
       "stylers": [
         {
           "visibility": "off"
         }
       ]
     },
     {
       "featureType": "road.local",
       "elementType": "labels",
       "stylers": [
         {
           "visibility": "off"
         }
       ]
     } */
]